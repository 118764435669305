import { Box, Typography, Divider, Card, IconButton, Tooltip, hexToRgb, Chip, Grid, AvatarGroup, Avatar, Menu, MenuItem, Badge, alpha, Button } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SearchIcon from '@mui/icons-material/Search';
import HardwareIcon from '@mui/icons-material/Hardware';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { PDrawerContext } from 'stores/PDrawerProvider';
import { motion, useAnimation } from "framer-motion";
import { KMInfraContext } from 'stores/KMInfraProvider';
import ticketApi from 'api/ticketApi';
import userApi from 'api/userApi';
import { formatLongDate } from 'utils/toolBox';
import { capitalize, lightOrDark } from 'utils/utils';
import clientApi from 'api/clientApi';
import { CheckRoleUtil } from 'components/KMCan/CheckRole';
import { KMSessionUserContext } from 'stores/KMSessionUserProvider';
import {  HourglassTopRounded, PersonAddOutlined } from '@mui/icons-material';

const Board = props => {
  const { formatTyping, getTypingBy } = useContext(KMInfraContext)
  let { addLayer, removeLayer, removeEveryLayer } = useContext(PDrawerContext);
  const { sessionUserStore } = useContext(KMSessionUserContext);
  
  const [data, setData] = useState(props.data ? props.data : [])
  const [users, setUsers] = useState([])
  const [clients, setClients] = useState([])

  const [openQuantify, setOpenQuantify] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  // TEMP, to rethink qualifying menu later
  const [currentTicket, setCurrentTicket] = useState(null);

  // Typings quantifiers
  let quantifiers = useContext(KMInfraContext).infraStore.typings.filter(e => e.typingTargetColumn === 'typingCodeEstimatedTimeRange')

  // Update data when props.data changes, given by parent component : Project.jsx, which fetches data from store
  useEffect(() => {
    setData(props.data)
  }, [props.data])

  // Fetch users and clients
  useEffect(() => {
    userApi.getAll().then((data) => {
      setUsers(data.datas)
    })
    clientApi.getAllOrga().then((data) => {
      setClients(data.datas)
    })
  }, [])

  // Animation
  const controls = useAnimation();
  const [direction, setDirection] = useState(1);
  const [offset, setOffset] = useState(0);

  // Animation
  useEffect(() => {
    controls.start({
      rotate: [0, 0, 50 * direction, 50 - direction, 0],
      transition: {
        repeat: 1,
        duration: 1,
        repeatType: "reverse",
      }
    });

    setTimeout(() => {
      setDirection(-1 * direction);
      if (offset === 0) setOffset(60);
      else setOffset(-60);
    }, 1000);
  }, [direction, controls]);

  const getUserName = (userId) => {
    let userName = users.find(e => e.userId === userId)
    if (userName) 
      return userName.userFirstName + " " + userName.userLastName
    else 
      return null
  }

  const canSupervisor = CheckRoleUtil({
    allowedRoles: ["supervisor"],
    sessionUserStore,
    debug: false,
  });

  const canClient = CheckRoleUtil({  
    allowedRoles: ["client"],
    sessionUserStore,
    debug: false,
  });

  const canMove = (section) => {
    if (canClient) {
      if (section.typingCode === 'functionalityStateToDo' || section.typingCode === 'bugStateToDo') return true
    }
    else if (canSupervisor) {
      return true
    }
    else return false
  }

  const onDragEnd = async ({ destination, source, draggableId }) => {
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return
    if (canSupervisor && (source.droppableId === 'functionalityStateToDo' && destination.droppableId === 'functionalityStateToDo')) return // Can't prioritize functionalities

    const newData = [...data]
    const sectionIndex = newData.findIndex(e => e.tickets.find(e => e.ticketId === parseInt(draggableId)))
    const ticketIndex = newData[sectionIndex].tickets.findIndex(e => e.ticketId === parseInt(draggableId))
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasDeployedDatetime = new Date()
    setData(newData)

    const sourceColIndex = data.findIndex(e => e.typingCode === source.droppableId)
    const destinationColIndex = data.findIndex(e => e.typingCode === destination.droppableId)
    const sourceCol = data[sourceColIndex]
    const destinationCol = data[destinationColIndex]

    const sourceSectionId = sourceCol.typingCode
    const destinationSectionId = destinationCol.typingCode

    const sourceTickets = [...sourceCol.tickets]
    const destinationTickets = [...destinationCol.tickets]
    let ticketId
    if (source.droppableId !== destination.droppableId) {
      const [removed] = sourceTickets.splice(source.index, 1)
      destinationTickets.splice(destination.index, 0, removed)
      data[sourceColIndex].tickets = sourceTickets
      data[destinationColIndex].tickets = destinationTickets
      ticketId = removed.ticketId
    } else {
      const [removed] = destinationTickets.splice(source.index, 1)
      destinationTickets.splice(destination.index, 0, removed)
      data[destinationColIndex].tickets = destinationTickets
      ticketId = removed.ticketId
    }
    try {
      ticketApi.updateOrder({ sourceTickets, destinationTickets, sourceSectionId, destinationSectionId, ticketId }).then(res => {
        setData(data)
      })
    } catch (err) {
      alert(err)
    }
    let id = parseInt(draggableId)
    ticketApi.changeTypingCode({
      ticketId: id,
      to: destination.droppableId,
    })
  }

  const setAnalysed = (ticket) => {
    const newData = [...data]
    const sectionIndex = newData.findIndex(e => e.tickets.find(e => e.ticketId === ticket.ticketId))
    const ticketIndex = newData[sectionIndex].tickets.findIndex(e => e.ticketId === ticket.ticketId)
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysed = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysed
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysedBy = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysed
      ? null :
      newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysedBy ? newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysedBy : sessionUserStore.userId
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysedDatetime = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysed
      ? null :
      newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysedDatetime ? newData[sectionIndex].tickets[ticketIndex].ticketFlagWasAnalysedDatetime : new Date()
    ticketApi.setAnalysed(ticket.ticketId)
    setData(newData)
  }

  const setQualified = (ticket, typingCodeEstimatedTimeRange) => {
    const newData = [...data]
    const sectionIndex = newData.findIndex(e => e.tickets.find(e => e.ticketId === ticket.ticketId))
    const ticketIndex = newData[sectionIndex].tickets.findIndex(e => e.ticketId === ticket.ticketId)
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualified = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualified
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualifiedBy = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualified
      ? null :
      newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualifiedBy ? newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualifiedBy : sessionUserStore.userId
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualifiedDatetime = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualified
      ? null :
      newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualifiedDatetime ? newData[sectionIndex].tickets[ticketIndex].ticketFlagWasQualifiedDatetime : new Date()
      newData[sectionIndex].tickets[ticketIndex].typingCodeEstimatedTimeRange = typingCodeEstimatedTimeRange
      ticketApi.setQualified(ticket.ticketId, typingCodeEstimatedTimeRange)
    setData(newData)
  }

  const setTicketDeveloped = (ticket) => {
    const newData = [...data]
    const sectionIndex = newData.findIndex(e => e.tickets.find(e => e.ticketId === ticket.ticketId))
    const ticketIndex = newData[sectionIndex].tickets.findIndex(e => e.ticketId === ticket.ticketId)
    newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDeveloped = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDeveloped
    newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDevelopedBy = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDeveloped
      ? null :
      newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDevelopedBy ? newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDevelopedBy : sessionUserStore.userId
    newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDevelopedDatetime = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDeveloped
      ? null :
      newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDevelopedDatetime ? newData[sectionIndex].tickets[ticketIndex].ticketFlagIsGettingDevelopedDatetime : new Date()
    ticketApi.setDevelopped(ticket.ticketId)
    setData(newData)
  }

  const setValidated = (ticket) => {
    const newData = [...data]
    const sectionIndex = newData.findIndex(e => e.tickets.find(e => e.ticketId === ticket.ticketId))
    const ticketIndex = newData[sectionIndex].tickets.findIndex(e => e.ticketId === ticket.ticketId)
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidated = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidated
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidatedBy = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidated
      ? null :
      newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidatedBy ? newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidatedBy : sessionUserStore.userId
    newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidatedDatetime = !!!newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidated
      ? null :
      newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidatedDatetime ? newData[sectionIndex].tickets[ticketIndex].ticketFlagWasValidatedDatetime : new Date()
    ticketApi.setValidated(ticket.ticketId)
    setData(newData)
  }

  const themeMode = sessionUserStore.clientId === 1 ? "light" : localStorage.getItem("theme-mode");

  const buttonColor = sessionUserStore.clientColorSecondary === "#1b1b1b" ? sessionUserStore.clientColorPrimary : sessionUserStore.clientColorSecondary ;

  return (
    <>
      <Divider sx={{ margin: '10px 0' }} />
      <DragDropContext onDragEnd={onDragEnd} >
        <Box sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'flex', xl: 'flex' }, alignItems: 'flex-start', overflowX: 'initial', }}>
          { data && data.map(section => {
              return (
                <div key={section.typingCode}>
                  <Droppable key={section.typingCode} droppableId={section.typingCode} isDropDisabled={!canMove(section)} >
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.droppableProps} sx={{ width: '350px', padding: '10px', marginRight: '10px', borderRadius: 2, backgroundColor: themeMode === 'dark' ? "#111" : "#EEF4FE", }}>
                        {/* section header */}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <Typography sx={{ display: "flex", justifyContent: 'space-between', width: '100%', }} component={'div'}>
                            {getTypingBy({ typingCode: section.typingCode }).typingLabel} {/*section.typingCode === 'bugStateFixed' && canSupervisor && (<Button variant="text" size='small' sx={{m:0, p:0}} color="primary" onClick={() => { ticketApi.archiveAllBugs() }}>tout archiver</Button>)*/} <Chip size="small" sx={{ backgroundColor: sessionUserStore.clientColorPrimary, color: lightOrDark(sessionUserStore.clientColorPrimary) === "dark" ? 'white' : 'black' }} label={section.tickets.length} />
                          </Typography>
                        </Box>
                        <Menu id="qualify-menu" anchorEl={anchorEl} open={openQuantify} onClose={() => {setOpenQuantify(false); setAnchorEl(null)}}>
                          {quantifiers?.map((quantifier, index) => (
                            <MenuItem key={index} onClick={(e) => {
                              setQualified(currentTicket, quantifier.typingCode) 
                              setOpenQuantify(false)
                              setAnchorEl(null)
                              e.stopPropagation()
                            }}>{quantifier?.typingLabel}</MenuItem>
                          ))}
                        </Menu>
                        {/* tickets */}
                        {section.tickets.map((ticket, index) => (
                            <Draggable key={ticket.ticketId} draggableId={ticket.ticketId.toString()} index={index} isDragDisabled={!canMove(section)}>
                              {(provided, snapshot) => (
                                <Card ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                  sx={{
                                    padding: '10px',
                                    marginBottom: '10px',
                                    cursor: snapshot.isDragging ? canMove(section) ? 'grabbing' : 'grab' : canMove(section) ? 'grab' : 'pointer',
                                    color: themeMode === 'dark' ? 'white' : 'black',
                                    boxShadow: `0px 0px 0px 1px ${themeMode === 'dark' ? "grey" : "black"} inset`,
                                    backgroundColor: currentTicket?.ticketId === ticket.ticketId ? alpha(sessionUserStore.clientColorSecondary, 0.25) : snapshot.isDragging ?
                                      themeMode === 'dark' ? '#323232' : '#f5f5f5'
                                      :
                                      themeMode === 'dark' ? "#1b1b1b" : "#fff",
                                  }}
                                  onClick={() =>
                                    {
                                    setCurrentTicket(ticket)
                                    addLayer({
                                      layerType: 'temporaryDrawer',
                                      layerAnchor: 'right',
                                      layerRoutePath: '/ticket/stepper/read/' + ticket.ticketId,
                                      layerCallbackOnClose: () => { setCurrentTicket(null) },
                                      layerCallbackOnAction: () => { },
                                    })}
                                  }
                                >
                                  {/* ticket title */}
                                  <Grid>
                                    <Typography>
                                      {ticket.ticketLabel === '' ? 'Untitled' : ticket.ticketLabel} <Typography color={"grey"} variant='caption'>#{ticket.ticketId}</Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid sx={{ display: "flex", justifyContent: 'space-between', width: '100%', }}>
                                    <Grid item>
                                      {/* ticket actions */}
                                        {section.typingCode !== 'functionalityStateDeployed' && section.typingCode !== 'functionalityStateToValidate' && section.typingCode !== 'bugStateFixed' && (
                                          <>
                                            <Tooltip sx={{ }} title={ticket.ticketFlagWasAnalysed ? `Analysée le ${formatLongDate(ticket.ticketFlagWasAnalysedDatetime)}` : "Pas encore analysée"}>
                                              <IconButton style={{ float: "left" }} onClick={canSupervisor ? (e) => { e.stopPropagation(); setAnalysed(ticket) } : (e) => {e.stopPropagation()}}>
                                                <SearchIcon fontSize='small' htmlColor={ticket.ticketFlagWasAnalysed ? buttonColor : 'grey'} />
                                              </IconButton>
                                            </Tooltip>

                                            <Tooltip title={ticket.ticketFlagWasQualified ? `Qualifiée le ${formatLongDate(ticket.ticketFlagWasQualifiedDatetime)} : ${getTypingBy({ typingCode: ticket.typingCodeEstimatedTimeRange }).typingLabel}` : "Pas encore qualifiée"}>
                                              <IconButton style={{ float: "left" }} onClick={canSupervisor ? (e) => { e.stopPropagation(); setAnchorEl(e.currentTarget); setCurrentTicket(ticket); ticket.ticketFlagWasQualified ? setQualified(ticket) : setOpenQuantify(true);} : (e) => {e.stopPropagation()}}>
                                                <HourglassTopRounded fontSize='small' htmlColor={ticket.ticketFlagWasQualified ? buttonColor : 'grey'} />
                                              </IconButton>
                                            </Tooltip>

                                            <Tooltip title={ticket.ticketFlagIsGettingDeveloped ? `En cours de traitement depuis le ${formatLongDate(ticket.ticketFlagIsGettingDevelopedDatetime)}` : "Pas encore en cours de traitement"}>
                                              <motion.div style={{ float: "left" }} animate={ticket.ticketFlagIsGettingDeveloped && controls}>
                                                <IconButton onClick={canSupervisor ? (e) => { e.stopPropagation(); setTicketDeveloped(ticket) } : (e) => {e.stopPropagation()}}>
                                                  <HardwareIcon fontSize='small' htmlColor={ticket.ticketFlagIsGettingDeveloped ? buttonColor : 'grey'} />
                                                </IconButton>
                                              </motion.div>
                                            </Tooltip>
                                          </>
                                        )}
                                      {section.typingCode === 'functionalityStateToValidate' && ((ticket.selectedAgents?.find(e => e.userId === sessionUserStore.userId) || canClient) || ticket.ticketFlagWasValidated === 1) && (
                                        <Tooltip title={ticket.ticketFlagWasValidated ? `Validation client le ${formatLongDate(ticket.ticketFlagWasValidatedDatetime)} par ${getUserName(ticket.ticketFlagWasValidatedBy)}` : "Pas encore validée par le client"} >
                                          <IconButton style={{ float: "left" }} onClick={(canClient || ticket.selectedAgents?.find(e => e.userId === sessionUserStore.userId)) ? (e) => { e.stopPropagation(); setValidated(ticket) } : (e) => {e.stopPropagation()}}>
                                            <TaskAltIcon fontSize='small' htmlColor={ticket.ticketFlagWasValidated ? buttonColor : 'grey'} />
                                          </IconButton>
                                        </Tooltip>
                                        )}
                                      {canClient && (section.typingCode === 'functionalityStateToDo' || section.typingCode === 'functionalityStateToValidate') && !ticket.ticketFlagWasValidated && (
                                        <Tooltip title={"Autoriser un agent à valider la fonctionnalité"}>
                                          <IconButton style={{ float: "left" }} onClick={(e) => { e.stopPropagation(); addLayer({
                                            layerType: 'temporaryDialog',
                                            layerAnchor: 'right',
                                            layerRoutePath: '/ticket/authorizeAgentToValidate/' + ticket.ticketId,
                                            layerCallbackOnClose: () => { },
                                            layerCallbackOnAction: () => { },
                                          })}}>

                                            <Badge sx={{ "& .MuiBadge-badge": { fontSize: 11, height: 16, minWidth: 16 }}} badgeContent={ticket.selectedAgents?.length} color="secondary">
                                              <PersonAddOutlined fontSize='small' htmlColor={ticket.ticketFlagWasValidated ? buttonColor : 'grey'} />
                                            </Badge>
                                          </IconButton>
                                        </Tooltip>
                                      )}

                                      {(section.typingCode === 'functionalityStateDeployed' || section.typingCode === 'bugStateFixed') && 
                                        <Typography variant='caption' color='grey'>
                                          {ticket.ticketFlagWasDeployedDatetime ? `${section.typingCode === 'functionalityStateDeployed' ? 'Déployé' : 'Résolu'} le ${formatLongDate(ticket.ticketFlagWasDeployedDatetime)}` : ""}
                                        </Typography>
                                      }
                                    </Grid>
                                    {/* {canClient && (
                                      <Grid item>
                                        <Chip size="small" sx={{m:"4px", backgroundColor: sessionUserStore.clientColorPrimary, color: lightOrDark(sessionUserStore.clientColorPrimary) === "dark" ? 'white' : 'black'}} label={clients.find(client => client.clientId === ticket.clientId) && capitalize(clients.find(client => client.clientId === ticket.clientId).clientLabel.split(' ')[clients.find(client => client.clientId === ticket.clientId).clientLabel.split(' ').length-1])} />
                                      </Grid>
                                    )} */}
                                    {/* ticket users */}
                                    <Grid item>
                                      <AvatarGroup  max={3} spacing={15}>
                                    { ticket.ticketUsers?.map((user, index) =>
                                      (
                                        <Tooltip key={user.userId} title={user.userFirstName + " " + user.userLastName}>
                                          <Avatar alt={user.userFirstName + " " + user.userLastName} sx={{backgroundColor: sessionUserStore.clientColorPrimary}} src={require(`../../assets/images/${user.userFirstName.charAt(0)}.jpeg`)} />
                                        </Tooltip>
                                      ))}
                                      </AvatarGroup>
                                    </Grid>
                                  </Grid>
                                </Card>
                              )}
                            </Draggable>
                          ))
                        }
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </div>
              )
            })
          }
        </Box>
      </DragDropContext>

    </>
  )
}

export default Board