import { useState, useContext, useEffect } from 'react';
import { Badge, Button, Divider, Grid, IconButton, MenuItem, Tooltip, Typography } from '@mui/material'
import { DeleteRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, RemoveRedEyeRounded, MarkEmailReadRounded } from '@mui/icons-material';
import { motion, AnimatePresence } from "framer-motion";
import { KMInfraContext } from 'stores/KMInfraProvider';
import { PDrawerContext } from 'stores/PDrawerProvider';
import messageApi from 'api/messageApi';

const NestedMessage = (props) => {
    let { notifications } = props
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(true);
    const [anchorElNestedNotificationMenu, setAnchorElNestedNotificationMenu] = useState(false);
    const { formatTyping, getTypingBy } = useContext(KMInfraContext)
    let { addLayer, removeLayer, removeEveryLayer } = useContext(PDrawerContext);
    useEffect(() => {
        setMessage(props.message)
        setLoading(false)
    }, [])
    const handleOpenNotificationNestedItems = (event) => {
        setAnchorElNestedNotificationMenu(!anchorElNestedNotificationMenu);
    };
    const updateMessageToRead = (actualTicket, message) => {
        let { messageId } = actualTicket
        let notificationKey = message.key
        try {
            messageApi.postReadNotif({ messageId }).then(res => { })
        } catch (err) {
            alert(err)
        }
        let newtickets = message.tickets.filter(ticket => ticket.messageId !== messageId)
        let newArrayOfNotification = {
            key: message.key,
            title: message.title,
            total: message.total - 1,
            tickets: newtickets
        }
        setMessage(newArrayOfNotification)
        if (newtickets.length === 0) {
            setMessage({})
            props.setNotifications(notifications.filter(message => message.key !== notificationKey))
        }
        if (notifications.length === 0) props.handleCloseNotificationMenu()
    }
    const updateMessageToReadAll = () => {
        let messageIds = []
        let notificationKey = message.key
        message.tickets.map(ticket => {
            messageIds.push(ticket.messageId)
        })
        try {
            messageApi.postReadAllNotif({ messageIds }).then(res => { })
        } catch (err) {
            alert(err)
        }

        setMessage({})
        props.setNotifications(notifications.filter(message => message.key !== notificationKey))
        if (notifications.length === 0) props.handleCloseNotificationMenu()
    }

    if (!!loading) return (<>chargement</>)
    return (
        <>
            {(Object.values(message).length > 0) && (
                <MenuItem key={message} sx={{ width: "400px" }}>
                    <Grid container sx={{ width: "400px" }}>
                        <Grid item xs={12} container p={2} justifyContent={"space-between"} alignContent={"center"} sx={{ minHeight: "40px" }} onClick={handleOpenNotificationNestedItems}>
                            <Grid item>
                                <Typography textAlign="center">{getTypingBy({ typingCode: message.title }).typingLabel} </Typography>
                            </Grid>
                            <Grid item>
                                <Grid item>
                                    <Badge badgeContent={message.tickets && message.tickets.length} color="primary"></Badge>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} onClick={handleOpenNotificationNestedItems}>
                            {!!anchorElNestedNotificationMenu && (
                                <Grid item xs={12}>
                                    <Grid container  alignItems={"center"} justifyContent={"space-between"}>
                                        <KeyboardArrowUpRounded></KeyboardArrowUpRounded>
                                        <Button onClick={() => updateMessageToReadAll()} variant="text">Tout marquer comme lu</Button>
                                    </Grid>
                                </Grid>
                            )}
                            {!anchorElNestedNotificationMenu && (
                                <Grid item xs={12}>
                                    <Grid container  alignItems={"center"} justifyContent={"space-between"}>
                                        <KeyboardArrowDownRounded></KeyboardArrowDownRounded>
                                        <Button onClick={() => updateMessageToReadAll()} variant="text">Tout marquer comme lu</Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {(anchorElNestedNotificationMenu && !!anchorElNestedNotificationMenu) && (
                            <Grid container item xs={12}>
                                {message && message.tickets && message.tickets.map(ticket => {
                                    return (
                                        <AnimatePresence mode="wait">
                                            <Grid xs={12}>
                                                <motion.div
                                                    initial={{
                                                        height: 0,
                                                        opacity: 0,
                                                    }}
                                                    animate={{
                                                        height: "auto",
                                                        opacity: 1,
                                                    }}
                                                    exit={{
                                                        height: 0,
                                                        opacity: 0,
                                                    }}
                                                    transition={{
                                                        duration: 0.2,
                                                        delay: 0.005,
                                                        damping: 60,
                                                        stiffness: 0,
                                                    }}
                                                >
                                                    <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ padding: "5px" }}>
                                                        <Grid item >
                                                            <Typography>Ticket #{ticket.ticketId}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container>
                                                                {!!ticket.ticketId && (
                                                                    <Grid item>
                                                                        <Tooltip title="Voir">
                                                                            <IconButton onClick={() => {
                                                                                addLayer({
                                                                                    layerType: 'temporaryDrawer',
                                                                                    layerAnchor: 'right',
                                                                                    layerRoutePath: '/ticket/stepper/read/' + ticket.ticketId,
                                                                                    layerCallbackOnClose: () => { },
                                                                                    layerCallbackOnAction: () => { },
                                                                                });
                                                                                props.handleCloseNotificationMenu();
                                                                                updateMessageToRead(ticket, message)
                                                                            }}>
                                                                                <RemoveRedEyeRounded color="primary"></RemoveRedEyeRounded>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                )}
                                                                <Grid item>
                                                                    <Tooltip title="Marquer comme lu">
                                                                        <IconButton >
                                                                            <MarkEmailReadRounded color="primary" onClick={() => updateMessageToRead(ticket, message)}></MarkEmailReadRounded>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider sx={{ minWidth: "100%" }}></Divider>
                                                </motion.div>
                                            </Grid>
                                        </AnimatePresence>
                                    )})}
                            </Grid>
                        )}
                    </Grid>
                </MenuItem>
            )}
        </>
    )
}

export default NestedMessage