import config from '../config';
import { restIO } from "utils/io"
const superagent = require('superagent');

const messageApi = {
  // CREATE
  newClient: (params) => superagent.post(config.app.apiUrlPublic + `/message`, params),

  // READ
  getAll: async () => {
    let endpoint = config.api.url + '/message'
    let payload = {}
    let responseKeepapi = await restIO({ endpoint, method: "get", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },
  getOne: (messageId) => superagent.get(config.app.apiUrlPublic + `/message/${messageId}`),
  // UPDATE
  postReadAllNotif: async (dataPayload) => {
    let endpoint = config.pf.url + "/pf/message/setToReadAll"
    let payload = dataPayload
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },
  postReadNotif: async (dataPayload) => {
    let endpoint = config.pf.url + "/pf/message/setToRead"
    let payload = dataPayload
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },
}

export default messageApi
