import { useContext, useEffect, useState } from "react"
import Project from "components/common/Project"
import TabFactory from "utils/TabFactory"
import clientApi from "api/clientApi"
import { CheckRoleUtil } from "components/KMCan/CheckRole"
import { KMSessionUserContext } from "stores/KMSessionUserProvider";

const Home = (props) => {
  const { sessionUserStore } = useContext(KMSessionUserContext);
  const [clients, setClients] = useState([])
  const { clientId } = sessionUserStore
  useEffect(() => {
      clientApi.getAll().then((data) => {
          setClients(data.datas)
      })
  }, [])

  const tabs = clients && clients.map(client => {
    return {
      label: client.clientLabel,
      Component: <>{<Project clientId={client.clientId} {...props} />}</>
    }
  })

  const canSupervisor = CheckRoleUtil({
    allowedRoles: ["supervisor"],
    sessionUserStore,
    debug: false,
  });

  if (!canSupervisor) {
    return <Project singleView clientId={clientId} {...props} />
  }

  return (
    <TabFactory defaultValue={0} tabs={tabs} />
  )
}

export default Home
