import superagent from 'superagent'
import { getCookie } from './cookie';


const defaultTimeout = {
    response: 5 * 60 * 1000, // 5 minutes
    deadline: 5 * 60 * 1000// 5 minutes
}
const defaultHeaders = [
    ['Content-Type', 'application/json'],
    ['Accept', 'application/json'],
]
const restIO = async ({ flagCSRF = true, method = "get", myQuery = {}, payload, endpoint, jwt = true, withCredential = true, headers = [], noDefaultHeader = false, timeout } = {}) => {
    const csrfToken = getCookie('_csrf_token');
    let apiResponseNetwork
    const agent = superagent.agent()
    if (['post', 'put', 'delete', 'get'].includes(method)) agent.query(myQuery)

    if (jwt === true) agent.set('X-JWT', window.localStorage.getItem('JWT'))
    if (withCredential === true) agent.withCredentials()
    let mergeTimeout = timeout ? { ...defaultTimeout, response: timeout } : defaultTimeout
    agent.timeout(mergeTimeout)
    if (noDefaultHeader === false) defaultHeaders.map(header => agent.set(header[0], header[1]))
    if (headers.length > 0) headers.map(header => agent.set(header[0], header[1]))
    if (['post', 'put', 'delete'].includes(method) && !!flagCSRF === true) agent.set('X-CSRF-TOKEN', csrfToken)

    try {
        if (['post', 'put', 'delete'].includes(method))
            apiResponseNetwork = await agent[method](endpoint).send(payload)
        else apiResponseNetwork = await agent[method](endpoint)
    } catch (error) {
        try {
            let responseKeepapi = JSON.parse(error.response.text)
            let { status } = responseKeepapi
            status = parseInt(status)
            if (status === 522) { // csrf
                setTimeout(() => window.location.href = "/logout", 3000)
            } else if (status === 401) { // unauthenticate
                setTimeout(() => window.location.href = "/logout", 3000)
            }
            else if (status === 422) { // maintenance
                setTimeout(() => window.location.href = "/logout", 3000)
            }
            return { code: "ko", ...responseKeepapi }
        } catch (deserializeError) {
            return { code: "ko", error }
        }
    }
    return apiResponseNetwork.body
}




const handleGeneriqueAction = ({
    enqueueSnackbar,
    method,
    endpoint,
    targetLoader = "loadingDefault",
    payload,
    query,
    successMessage = "Les modifications ont été apportées",
    flagNoSuccessMessage = false, // ability to not display a message on success
    errorMessage = "Une erreur s'est produite",
    reloadStore = false,
    callback,
    callbackOK,
    callbackKO,
    timeout = 60 * 1000 * 60,
    forceErrorInStore,
} = {}) => async (loader, setLoader) => {
    if (loader === targetLoader) return
    if (setLoader) setLoader(targetLoader)
    if (!endpoint) {
        setTimeout(() => {
            if (setLoader) setLoader("")
            if (reloadStore) reloadStore()
            if (callback) callback()
            return
        }, 500)
        return
    }
    setTimeout(
        async () => {
            let responseKeepapi
            try {
                responseKeepapi = await restIO({ method, endpoint, payload, query, timeout })
                if (setLoader) setLoader("")
            } catch (error) {
                enqueueSnackbar('Une erreur inattendue empêche le bon fonctionnement de cette action. Nous informons nos développeurs', { variant: "warning" })
                if (setLoader) setLoader("")
                return
            }

            let { datas, extraData, details, code, status, error, showBackendMessage = false, message: backendMessage } = responseKeepapi
            status = parseInt(status)

            let variant = code !== 'ok' ? 'error' : 'success'
            let message = code !== 'ok' ? `${errorMessage}.` : successMessage
            if (!!flagNoSuccessMessage === true && code === 'ok') { }
            else {
                if (code !== 'ok') console.log(`${errorMessage}.${error}`)
                if (showBackendMessage === true) enqueueSnackbar(backendMessage, { variant: 'warning', autoHideDuration: 10000 })
                else if (status === 400) {
                    for (let [key, value] of Object.entries(backendMessage)) {
                        enqueueSnackbar(value.msg, { variant, autoHideDuration: 10000 })
                        if (forceErrorInStore) forceErrorInStore({ forceKey: value.param, forceHelperText: value.msg })
                    }
                }
                else if (status === 522) {
                    enqueueSnackbar("Votre session a expiré.", { variant, autoHideDuration: 10000 })
                    setTimeout(() => window.location.href = "/logout", 1000);
                    return
                }

                else enqueueSnackbar(message, { variant })
            }
            if (reloadStore) reloadStore()
            if (callback) callback()
            if (code !== 'ok' && callbackKO && status >= 400 && status <= 599) callbackKO(datas, extraData, details, code, error, showBackendMessage, message)
            if (code === 'ok' && callbackOK && status >= 100 && status <= 299) callbackOK(datas, extraData, details, code, error, showBackendMessage, message)
            return
        }, 500)
}

export { restIO, handleGeneriqueAction }
