
// type: 'light',
//         primary: {
//         main: '#0360b7',
//         },
//         secondary: {
//         main: '#fd4a7d',
//         },

/**
 * primary   : #bfa056
 * secondary : #1b1b1b

    gold: #bfa056
    plurelya : #32285c
    plurelya orange : #f0ab00
    plurelya corrected for both dark and light mode : #8d15a8
    keepapi orange : #f49e12

 */

const theme = {
    palette: {
        mode: 'dark',
        type: 'dark',
        primary: {
            main: '#1b1b1b',
        },
        secondary: {
            main: '#bfa056',
        },
        red: {
            main: '#ff0f00',
        },
        green: {
            main: '#32cd32',
        },
        lightgrey: {
            main: '#757575',
        },
        coolgray: {
            main: '#1b1b1b',
        },
        background: {
            default: "#1b1b1b"
        },
    },
    // typography: {
    //     button: {
    //     textTransform: 'none'
    //     },
    padding: 0,
    overrides: {
        MuiSwitch: {
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: 8,
            },
            switchBase: {
                padding: 1,
                '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + $track': {
                    opacity: 1,
                    border: 'none',
                },
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 13,
                border: '1px solid #bdbdbd',
                backgroundColor: '#fafafa',
                opacity: 1,
                transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
        },
    },
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#1b1b1b'
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    marginLeft: "12px",
                    marginTop: "10px"
                }
            }
        },
        // NestedNavMenu: {
        //     styleOverrides: {
        //         opacity: 0
        //     },
        //     nested: {
        //         paddingLeft: 4,
        //     },
        //     nestedSecondLevel: {
        //         paddingLeft: 8
        //     }
        // },
    }
}

export default theme;