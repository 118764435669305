// import { v4 as uuidv4 } from "uuid";

import { blue, blueGrey, green, grey, purple, red } from '@mui/material/colors';
import React from "react";
import { SvgDynamicIcon } from '../components/SvgDynamicIcon';

const dayjs = require("dayjs");
// import "dayjs/locale/fr.js";
dayjs.locale("fr");
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
/**
 *
 * capitalize the first letter
 *
 */
const capitalize = (value) => {
  if (value) return value.charAt(0).toUpperCase() + value.substring(1);
};

/**
 *
 * format firstNAme and LastName with code and prefix
 *
 */
const codeAndfullName = (
  isCode,
  code,
  firtName,
  lastName,
  isPrefix = false,
  prefix = "a"
) => {
  if ((code, firtName, lastName))
    return `${isPrefix ? prefix : ""}${isCode ? code : ""} ${isCode ? "/" : null
      } ${firtName === "_" ? "" : firtName} ${lastName === "_" ? "" : lastName}`;
};

/**
 *
 * capitalize the first letter and return initial
 *
 */
const capitalizeAndInitial = (value) => {
  const valueArrays = value.split(" ");
  const resValue = valueArrays.map((valueArray) => {
    return valueArray.charAt(0).toUpperCase();
  });
  let result = "";
  for (let value of resValue.values()) {
    result += value;
  }
  return result;
};

// console.log(capitalizeAndInitial("hello world"));

/**
 *
 * convert a first and last name to uppercase initial
 *
 */

const initial = (first, last) => {
  return `${first.charAt(0)}${last.charAt(0)}`.toUpperCase();
};

// console.log(initial("toto", "oto"));

/**
 *
 * calculate total amount with option
 * dec -> Boolean -> 0.0000
 * If dec option (nbdec,euro,percent)
 * nbdec -> int -> number of decimal places
 * euro -> Boolean -> displays the euro sign €
 * percent  -> Boolean -> displays the percent sign %
 *
 */

const total = (
  arrayValue = [],
  dec = false,
  nbdec = 0,
  euro = false,
  percent = false
) => {
  if (dec) {
    return `${arrayValue
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
      .toFixed(nbdec)}${euro ? " €" : ""}${percent ? " %" : ""}`;
  } else {
    return parseInt(
      arrayValue.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );
  }
};

// console.log(total([2, 3], 2));

/**
 *
 * Capitalize all words
 *
 */

const capitalizeWords = (value) => {
  if (value) {
    const words = value.replaceAll("_", " ").toLowerCase().split(" ");
    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  }
};

/**
 *
 *  bytes -> String -> Format octet and human value
 *
 */
const formatBytes = function (bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const index = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const index2 = Math.floor(Math.log(bytes) / Math.log(index));
  let truc = parseFloat((bytes / Math.pow(index, index2)).toFixed(dm))
  if (isNaN(truc)) {
    return ''
  }
  let myReturn = truc + " " + sizes[index2];
  return myReturn
};

/**
 *
 *  return -> String -> return if even or odd day
 *
 */


// const password = `${evenOrOdd()}Ga10010QT!${dayjs().format("DDMM")}`;

/**
 *
 *  return -> icon  -> iconType () -> iconSize (2xs,xs,sm(default),lg,xl,2xl Or 1x => 10x)
 *  https://fontawesome.com/v6/docs/web/style/size
 *
 */
const iconFile = (iconType, iconSize = "sm") => {
  switch (iconType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <SvgDynamicIcon size={iconSize} fillColor={green[600]} name="fileExcel" />
    case 'application/vnd.oasis.opendocument.spreadsheet':
        return <SvgDynamicIcon size={iconSize} fillColor={green[600]} name="fileExcel" />
    case 'application/vnd.ms-excel':
        return <SvgDynamicIcon size={iconSize} fillColor={green[600]} name="fileExcel" />
    case 'application/msword':
        return <SvgDynamicIcon size={iconSize} fillColor={blue[600]} name="fileWord" />
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <SvgDynamicIcon size={iconSize} fillColor={blue[600]} name="fileWord" />
    case 'text/csv':
        return <SvgDynamicIcon size={iconSize} fillColor={green[600]} name="fileCsv" />
    case 'image/jpeg':
        return <SvgDynamicIcon size={iconSize} fillColor={blueGrey[500]} name="fileImage" />
    case 'image/jpg':
        return <SvgDynamicIcon size={iconSize} fillColor={blueGrey[500]} name="fileImage" />
    case 'image/png':
        return <SvgDynamicIcon size={iconSize} fillColor={blueGrey[500]} name="fileImage" />
    case 'image/webp':
        return <SvgDynamicIcon size={iconSize} fillColor={blueGrey[500]} name="fileImage" />
    case 'application/pdf':
        return <SvgDynamicIcon size={iconSize} fillColor={red[600]} name="filePdf" /> 
    default:
        return <SvgDynamicIcon size={iconSize} name="file" />
  }
};

/**
 *
 *  return -> String -> return extention file
 *  ext = mimeType
 *
 */
const extByMimeType = (ext) => {
  switch (ext) {
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xlsx";
    case "application/vnd.ms-excel":
      return "xls";
    case "text/csv":
      return "csv";
    case "image/jpg":
    case "image/jpeg":
      return "jpeg";
    case "image/png":
      return "png";
    case "application/pdf":
      return "pdf";
    case "text/xml":
      return "xml";
    default:
      return "type de fichier inconnu";
  }
};

/**
 *
 *  return -> Boolean -> if date is valid -> "YYYY-MM-DD" = "2022-01-01"
 *
 */

const checkDateFormatedIsValid = (date) => {
  return dayjs(date, "YYYY-MM-DD", true).isValid();
};

// console.log("***************");
// console.log("date", checkDateFormatedIsValid("2022-06-01"));
// console.log("***************");

const checkDateIsValid = (date) => {
  return dayjs(date).isValid();
};

// console.log("***************");
// console.log("date", checkDateIsValid("2022-06-20T14:44:25.191Z"));
// console.log("***************");

/**
 *
 *  return -> String -> return date format -> 01 janvier 2022
 *
 */
const formatLongDate = (date) => {
  return dayjs(date).format("DD MMMM YYYY");
};
const formatSimpleDate = (date) => {
  return dayjs(date).format("DD/MM/YYYY");
};
const formatLongDatetime = (date) => {
  return dayjs(date).format("DD MMMM YYYY - HH:mm:ss");
};

const replaceMonthWithInt = (date) => {
  const correction = {
    "janvier": "01",
    "février": "02",
    "mars": "03",
    "avril": "04",
    "mai": "05",
    "juin": "06",
    "juillet": "07",
    "août": "08",
    "septembre": "09",
    "octobre": "10",
    "novembre": "11",
    "décembre": "12",
    'January': '01',
    'February': '02',
    'March': '03',
    'April': '04',
    'May': '05',
    'June': '06',
    'July': '07',
    'August': '08',
    'September': '09',
    'October': '10',
    'November': '11',
    'December': '12',
  };
  Object.keys(correction).forEach((key) => {
    date = date.replace(key, correction[key]);
  });
  return date;
};

function daysInMonth(date) {
  const month = dayjs(date).format("MM");
  const year = dayjs(date).format("YYYY");
  return new Date(year, month, 0).getDate();
}


/* 
    @param date: date object
    return: array of days in month from today or from the start of the month
*/
const remainingDaysInMonth = (date, fromToday) => {
    const today = new Date();
    let remaingDays

    if (fromToday && today.getMonth() === date.getMonth()) {
        remaingDays = Array.from({ length: daysInMonth(date) - today.getDate() + 1 }, (_, i) => i + today.getDate());
    }
    else {
        remaingDays = Array.from({ length: daysInMonth(date) }, (_, i) => i + 1);
    }
    return remaingDays;
}


const formatSharepointLongDatetime = (date) => {
  const correction = {
    "January": "janvier",
    "February": "février",
    "March": "mars",
    "April": "avril",
    "May": "mai",
    "June": "juin",
    "July": "juillet",
    "August": "août",
    "September": "septembre",
    "October": "octobre",
    "November": "novembre",
    "December": "décembre",
  };
  Object.keys(correction).forEach((key) => {
    date = date.replace(key, correction[key]);
  });
  return date;
};
const euro = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
});


const add3DotsKeepExtension = (string, limit) => {
    let dots = '…';
    let extension = string.split('.').pop();
    if (string.length > limit) {
        string = string.substring(0, limit-extension.length) + dots + ' .' + extension;
    }
    return string;
};

export {
  euro,
  total,
  initial,
  iconFile,
  capitalize,
  formatBytes,
  daysInMonth,
  extByMimeType,
  formatLongDate,
  codeAndfullName,
  capitalizeWords,
  formatSimpleDate,
  checkDateIsValid,
  formatLongDatetime,
  replaceMonthWithInt,
  capitalizeAndInitial,
  add3DotsKeepExtension,
  checkDateFormatedIsValid,
  formatSharepointLongDatetime,
};
