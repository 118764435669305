import config from 'config'
import { useEffect } from 'react'
import { deleteAllCookies } from 'utils/cookie'
import { restIO } from 'utils/io'
import { removeServiceWorker } from 'utils/serviceWorker'
import { useLocation } from 'react-router-dom';

const NotLogged = () => {
    let location = useLocation();
    useEffect(() => {
        let stopRedirect = window.localStorage.getItem('stopRedirect') || false
        if (!!stopRedirect === true) debugger
        removeServiceWorker()
        deleteAllCookies() // deleteAllCookies(["kmSession"])
        // deloguer le coté serveur (jwt blacklist, bdd, cookie etc)
        const logout = async () => {
            const oauthLogoutUrl = config.oauth.logout.url
            try { await restIO({ endpoint: oauthLogoutUrl, jwt: true }) } catch (error) { console.log(error) }
        }
        logout()
        window.sessionStorage.clear()
        window.localStorage.removeItem("JWT")
        window.localStorage.removeItem("redirectToReferrer")
        window.localStorage.removeItem("refreshLastAliveDatetime")
        window.localStorage.removeItem("refreshNotification")
        // nous stockons en session local sur le domaine la route demandé par l'utilisateur
        let { from } = location.state || { from: { pathname: '/dashboard' } }
        window.localStorage.setItem('redirectToReferrer', JSON.stringify(from))
        // redirection vers la page de logout du front
        window.location.href = config.front.logout.url
    }, [])
    return null;
}
export default NotLogged
