import * as Msal from 'msal';

const msalConfig = {
    auth: {
        clientId: "2674eb75-2b37-4cea-ad29-78189eac432d",
        authority: "https://login.microsoftonline.com/8f5be495-a6d4-40a7-a42a-c5f26dd46437",
        redirectUri: window.location.origin + "/redirect",
    },
    cache: {
        cacheLocation: "localStorage", 
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};  

// Add here the scopes to request when obtaining an access token for MS Graph API
// for more, visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/scopes.md
const loginScopes = {
    scopes: ["openid", "profile", "User.Read"]
};

// Add here scopes for access token to be used at MS Graph API endpoints.
const tokenScopes = {
    // scopes: ["Mail.Read", "Calendars.ReadBasic", "Calendars.Read", "Calendars.ReadWrite", "Calendar", "Contacts.Read", "Group.Read.All", "Group.ReadWrite.All"]
    // scopes: ["Mail.Read", "Group.Read.All", "Group.ReadWrite.All"]
    scopes: ["Mail.Read"]
};

const myMSALObj = new Msal.UserAgentApplication(msalConfig);

function signIn() {
    return myMSALObj.loginPopup(loginScopes)
    .then(loginResponse => {
        // console.log("id_token acquired at: " + new Date().toString());
        // console.log(loginResponse);
        
        if (myMSALObj.getAccount()) {
            // console.log(myMSALObj.getAccount());
        }
    }).catch(error => {
        console.log(error);
    });
}

function signOut() {
    myMSALObj.logout();
}

// check if user is logged in, if not, login
function verifyIsLoggedInOrLogin() {
    let account = myMSALObj.getAccount();
    if (account) {
        // console.log("user is logged in with token valid until " + new Date(account.idToken.exp * 1000).toString());
        return Promise.resolve(true)
    } else {
        // console.log("user is not logged in, logging in");
        return Promise.resolve(signIn());
    }
}

function getTokenPopup(request) {
    return myMSALObj.acquireTokenSilent(request)
    .catch(error => {
        console.log(error);
        console.log("silent token acquisition fails. acquiring token using popup");
        
        // fallback to interaction when silent call fails
        return myMSALObj.acquireTokenPopup(request)
        .then(tokenResponse => {
            return tokenResponse;
        }).catch(error => {
            console.log(error);
        });
    });
}

export { verifyIsLoggedInOrLogin, getTokenPopup, signOut, tokenScopes}