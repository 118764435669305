import config from '../config';
import { restIO } from "utils/io"
const superagent = require('superagent');

const clientApi = {

  // READ
  getAll: async () => {
      let endpoint = config.api.url + '/users'
      let payload = {}
      let responseKeepapi = await restIO({ endpoint, method: "get", payload })
      let { datas, extraData, details, code, error } = responseKeepapi
      return { datas, extraData, details, code, error}
  },
}

export default clientApi
