import config from "config.js";
import { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { KMInfraContext } from "stores/KMInfraProvider";
import { useContext } from "react";
import { makeStyles } from "tss-react/mui";
import { KMSessionUserContext } from "stores/KMSessionUserProvider";
import { CheckPermissionUtil } from "components/KMCan/CheckPermission";
import { CheckRoleUtil } from "components/KMCan/CheckRole";
import Helmet from "react-helmet";
import React from "react";
import DisplayLayerFromDrawerStore from "components/navigation/DisplayLayerFromDrawerStore";
import PFormProvider from "stores/PFormProvider";
import Draggable from "react-draggable";
import PaceLoader from "components/pace/PaceLoader";
import { Box, CssBaseline, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import Home from "views/Home";
import Menu from "components/common/Menu";
import theme from "assets/jss/theme";
const useStyles = makeStyles()((theme) => {
  const drawerWidth = 260;
  const transition = {
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
  };
  const container = {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
  };

  return {
    privateLayout: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        position: "relative",
      },
    },
    background: {
      backgroundSize: "cover",
      backgroundPositionY: "100%",
      backgroundPositionX: "0%",
      backgroundRepeat: "no-repeat",
      flex: "1 1 auto",
      position: "relative",
      top: "0",
    },
    mainPanel: {
      marginTop: "30px",
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginTop: "50px",
      },
      overflow: "auto",
      position: "relative",
      float: "right",
      ...transition,
      width: "100%",
      overflowScrolling: "touch",
    },

    contentNoMobile: {
      padding: "0px 20px",
    },
    content: {
      marginTop: "5px",
      padding: "0px 0px",

      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
        padding: "0px 0px",
      },
      minHeight: "calc(100vh - 123px)",
      maxWidth: "100vw"
    },
    container: {
      ...container,
      [theme.breakpoints.down("md")]: {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },
  };
});

const PrivateLayout = (props) => {
  const { classes, cx } = useStyles();
  const { sessionUserStore, sessionUserStoreRightPermissionDenied } =
    useContext(KMSessionUserContext);
  const { infraStore, infraStoreRightPermissionDenied } =
    useContext(KMInfraContext);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const [mobileOpen, setMobileOpen] = useState(false);

  // client theming
  const [currentTheme, setCurrentTheme] = useState(createTheme(theme))
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const preferenceUpdate = () => {
    if (prefersDarkMode) {
      localStorage.setItem("theme-mode", "dark");
      return "dark"
    } else {
      localStorage.setItem("theme-mode", "light");
      return "light"
    }
  }

  const themeMode =  sessionUserStore.clientId === 1 ? "light" :
    localStorage.getItem("theme-mode") === null ? 
    preferenceUpdate() : localStorage.getItem("theme-mode")

  const themeHandler = (themeMode, primary, secondary) => 
      setCurrentTheme(createTheme({
          ...theme,
          palette: {
              ...theme.palette,
              mode: themeMode,
              type: themeMode,
              primary: {
                  main: primary,
              },
              secondary: {
                  main: secondary,
              },
              background: {
                  default: themeMode === 'dark' ? "#1b1b1b" : "#fff"
              },
          },
          components: {
              ...theme.components,
              MuiTooltip: {
                  styleOverrides: {
                      tooltip: {
                          backgroundColor: themeMode === 'dark' ? "#5e5e5e" : "#757575",
                          fontSize: "0.9rem",
                      }
                  }
              },
              MuiInputLabel: {
                  styleOverrides: {
                      root: {
                          color: themeMode === 'dark' ? "fff" : "#202020",
                          fontWeight: 500
                      }
                  }
              },
          }
  }));

  const [currentThemeMode, setCurrentThemeMode] = useState();


  const changeThemeMode = () => {
      if (currentThemeMode ===  "dark") {
          setCurrentThemeMode("light");
          localStorage.setItem("theme-mode", "light");
      } else {
          setCurrentThemeMode("dark");
          localStorage.setItem("theme-mode", "dark");
      }
  };

  useEffect(() => {

    let themeMode =  sessionUserStore.clientId === 1 ? "light" :
    localStorage.getItem("theme-mode") === null ?
    preferenceUpdate() : localStorage.getItem("theme-mode")

    let latestPrimary = localStorage.getItem('latest-primary-color')
    let latestSecondary = localStorage.getItem('latest-secondary-color')

    // use accordingly
    if (sessionUserStore.clientColorPrimary && sessionUserStore.clientColorSecondary) {
      themeHandler(themeMode, sessionUserStore.clientColorPrimary, sessionUserStore.clientColorSecondary)
    } else if (latestPrimary && latestSecondary) {
      themeHandler(themeMode, latestPrimary, latestSecondary)
    } else {
      themeHandler(themeMode, "#3f51b5", "#f50057")
    }
    
    setCurrentThemeMode(themeMode);

    // set scss variables
    document.documentElement.style.setProperty('--background-color', sessionUserStore.clientColorPrimary ? sessionUserStore.clientColorPrimary : "#fff")
    document.documentElement.style.setProperty('--secondary-background-color', themeMode === 'dark' ? "#2c2c2c" : "#f5f5f5") 
    
    // wait for sessionUserStore.clientColorPrimary to be set or use latest-colors
    sessionUserStore.clientColorPrimary && localStorage.setItem('latest-primary-color', sessionUserStore.clientColorPrimary)
    sessionUserStore.clientColorSecondary && localStorage.setItem('latest-secondary-color', sessionUserStore.clientColorSecondary)

  }, [sessionUserStore.clientColorPrimary, sessionUserStore.clientColorSecondary, themeMode, currentThemeMode])
    

  // resizeFunction: add an event listener on window resizeFunction
  useEffect(() => {
    window.addEventListener("resize", resizeFunction);
    return () => {
      window.removeEventListener('resize', resizeFunction);
    }
  }, []);

  const resizeFunction = () => {
    if (window.innerWidth >= 900) setMobileOpen(false)
  };

  if (sessionUserStoreRightPermissionDenied === true) return null;
  if (infraStoreRightPermissionDenied === true) return null;
  let sidebarRoutes = infraStore.privateRoutes
    .filter((privateRoute) => !!privateRoute.routeFlagDisplayInReactSideBarMenu)
    .filter((privateRoute) => {
      let { routeListRBAC, routePath } = privateRoute;
      let canView = CheckPermissionUtil({
        sessionUserStore,
        perform: routeListRBAC,
        debug: false,
      });
      let routeRBACs =
        routeListRBAC && routeListRBAC.indexOf(",") > 0
          ? routeListRBAC.split(",").map((rbac) => rbac.trim())
          : [routeListRBAC];

      if (!!canView === false) {
        canView = CheckRoleUtil({
          sessionUserStore,
          allowedRoles: routeRBACs,
          debug: false,
        });
      }

      return !!canView;
    });
  let tempSubSidebarRoutes = [...sidebarRoutes]
  sidebarRoutes.map(sidebarRoute => {
    sidebarRoute.subMenus = []
    tempSubSidebarRoutes.map(tempSubSidebarRoute => {
      if (tempSubSidebarRoute.parentRouteId === sidebarRoute.routeId) {
        sidebarRoute.routeReactComponent = () => null
        sidebarRoute.subMenus.push(tempSubSidebarRoute)
      }
    })
    return sidebarRoute
  })
  sidebarRoutes = sidebarRoutes.filter(sidebarRoute => sidebarRoute.parentRouteId === 0)
  sidebarRoutes = sidebarRoutes.sort((sidebarRouteA, sidebarRouteB) => sidebarRouteA.routeOrder - sidebarRouteB.routeOrder)


  return (
    <ThemeProvider theme={currentTheme}>
        <CssBaseline/>
    <>
      <Box sx={{ width: "100%", position: "absolute", top: 0, height: "1px" }}>
        <PaceLoader />
      </Box>
      <Menu routes={sidebarRoutes} changeThemeMode={changeThemeMode} />
      <div
        className={cx({
          [classes.privateLayout]: true,
        })}
      >
        <div className={classes.background}>
          <div>
            <div className={classes.content}>
              <div className={classes.container}>
                <Suspense>
                  <Routes>
                    {infraStore.privateRoutes
                      .filter((privateRoute) => privateRoute.routeReactComponent)
                      .sort(
                        (privateRouteCurrent, privateRouteNext) =>
                          privateRouteCurrent.routeOrder - privateRouteNext.routeOrder
                      )
                      .map((privateRoute, key) => {
                        let {
                          routeListRBAC,
                          routeLabel,
                          routeEntityIdName,
                          routePath,
                          routeReactComponent,
                          routeJSONParam = {},
                        } = privateRoute;
                        if (!routePath.startsWith("/")) {
                          console.log(
                            "missconfiguration of config file. must begin with /. see : ",
                            routePath
                          );
                        }
                        try {
                          routeJSONParam = JSON.parse(routeJSONParam);
                          // config.api.inquiry.listItemSavingToTreat.url)
                        } catch (error) {

                          console.log("missConfiguration of routeJSONParam routePath :", routePath, " ", error);
                        }
                        let {
                          apiList,
                          detailPanelReactComponent,
                          detailPanelReactComponentProp,
                          formCode,
                          computeWhenChanges = [],
                          hiddenFieldType,
                          filterCode,
                          reportCode,
                          staticNavigations = [],
                          staticTabNavigation = "",
                          staticHelp = "",
                          detailPanelExpandIcon = "",
                          detailPanelCollapseIcon = '',
                          initialSteps = [],
                          componentProps = {}
                        } = routeJSONParam || {};
                        try {
                          if (apiList) apiList = eval("myConfig.api." + apiList + ".url");
                        } catch (error) {
                          console.log(
                            "missconfiguration of config file. see : ",
                            routePath,
                            routeJSONParam,
                            error
                          );
                        }
                        let canView = CheckPermissionUtil({
                          sessionUserStore,
                          perform: routeListRBAC,
                          debug: false,
                        });
                        let routeRBACs =
                          routeListRBAC && routeListRBAC.indexOf(",") > 0
                            ? routeListRBAC.split(",").map((rbac) => rbac.trim())
                            : [routeListRBAC];
                        if (!!canView === false) {
                          canView = CheckRoleUtil({
                            sessionUserStore,
                            allowedRoles: routeRBACs,
                            debug: false,
                          });
                        }
                        if (!!canView === false) return null;
                        let MyComponent = lazy(() => import(`views/${routeReactComponent}`));
                        return (
                          <Route
                            path={routePath}
                            exact
                            element={
                              <PFormProvider formCode={formCode}>
                                <MyComponent
                                  apiList={apiList}
                                  detailPanelReactComponent={detailPanelReactComponent}
                                  detailPanelReactComponentProp={detailPanelReactComponentProp}
                                  hiddenFieldType={hiddenFieldType}
                                  formCode={formCode}
                                  computeWhenChanges={computeWhenChanges}
                                  filterCode={filterCode}
                                  reportCode={reportCode}
                                  routeLabel={routeLabel}
                                  routeEntityIdName={routeEntityIdName}
                                  staticNavigations={staticNavigations}
                                  staticTabNavigation={staticTabNavigation}
                                  staticHelp={staticHelp}
                                  detailPanelExpandIcon={detailPanelExpandIcon}
                                  detailPanelCollapseIcon={detailPanelCollapseIcon}
                                  initialSteps={initialSteps}
                                  sessionUserStore={sessionUserStore}
                                  componentProps={componentProps}
                                />
                              </PFormProvider>
                            }
                            key={key}
                          />
                        )
                      })}
                  </Routes>
                </Suspense>
              </div>
            </div>
          </div>
          <DisplayLayerFromDrawerStore></DisplayLayerFromDrawerStore>
        </div>
      </div >
    </>
        </ThemeProvider>
        
  );
};
export default React.memo(PrivateLayout);
