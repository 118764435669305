import { Button, Fade, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useEffect } from 'react';
import config from '../config';
import superagent from 'superagent';
import { CloseRounded } from '@mui/icons-material';
import Exabanque from 'assets/icons/exabanque.png'
import { blueGrey, green, grey } from '@mui/material/colors';
import { SvgDynamicIcon } from '../components/SvgDynamicIcon';
import { restIO } from './io';
import { oauth } from 'oauth/oauth';

// const queryParams = new Proxy(new URLSearchParams(window.location.search), {
//   get: (searchParams, prop) => searchParams.get(prop),
// });

const SocketClient = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    const id = setInterval(() => {
      let refreshNotification = new Date(window.localStorage.getItem('refreshNotification'))
      let now = new Date();
      let dif = now.getTime() - refreshNotification.getTime();
      let secondFromRefreshNotificationToNow = dif / 1000;
      if (secondFromRefreshNotificationToNow < 20) fetchAndDisplaySnackBar()
      else if (secondFromRefreshNotificationToNow > 30 && secondFromRefreshNotificationToNow < 35) fetchAndDisplaySnackBar()
      else if (secondFromRefreshNotificationToNow > 60 && secondFromRefreshNotificationToNow < 65) fetchAndDisplaySnackBar()
      else if (secondFromRefreshNotificationToNow > 60 * 2 && secondFromRefreshNotificationToNow < 60 * 2 + 5) fetchAndDisplaySnackBar()
      else if (secondFromRefreshNotificationToNow > 60 * 5 && secondFromRefreshNotificationToNow < 60 * 5 + 5) fetchAndDisplaySnackBar()
      else if (secondFromRefreshNotificationToNow > 60 * 10 && secondFromRefreshNotificationToNow < 60 * 10 + 5) fetchAndDisplaySnackBar()

    }, 4000
    )
    return () => clearInterval(id);

  }, [])
  useEffect(() => {
    const id = setInterval(async () => {
      let refreshLastAliveDatetime = new Date(window.localStorage.getItem('refreshLastAliveDatetime'))
      let now = new Date();
      let dif = now.getTime() - refreshLastAliveDatetime.getTime();
      let secondFromRefreshLastAliveDatetimeToNow = dif / 1000;
      if (secondFromRefreshLastAliveDatetimeToNow < 60 * 60) await oauth.lastAliveDatetime()
    }, 1000 * 60 * 60
    )
    return () => clearInterval(id);

  }, [])
  const fetchAndDisplaySnackBar = async () => {
    let pfNotificationReadUrl = config.pf.url + "/pf/notification/read";
    let responseKeepapi = await restIO({
      method: "get",
      endpoint: pfNotificationReadUrl,
      jwt: true,
    });

    let { datas, extraData, details, code, error } = responseKeepapi;
    if(datas) {
    datas.map(data => {

      let { notificationMessage = "", notificationPayload = {}, notificationLevel = "success", notificationPersist = false, notificationAutoHideDurationInMS = 5 * 1000 } = data;
      let message = notificationMessage
      let payload = null
      try {
        payload = JSON.parse(notificationPayload)
      } catch (error) {

      }

      let level = notificationLevel
      let persist = notificationPersist
      let autoHideDuration = notificationAutoHideDurationInMS

      if (persist === true) autoHideDuration = null
      // redirection lors d'un click and pay
      if (payload && payload.redirectToPaymentURL) {
        window.location.href = payload.redirectToPaymentURL;
        return;
      }
      const algo = async (doc, { forceFlagDownload = false } = {}) => {
        let {
          temporaryDownloadUrl,
          // docId,
          docLabel,
          // docSizeInByte,
          // docTypeCode,
          // docMimeType,
          // docPath,
          docTypeFlagPopable = true,
        } = doc;

        if (forceFlagDownload === true) {
          let response = await superagent.get(temporaryDownloadUrl).responseType('arraybuffer');
          let blob = response.body;
          const streamURL = window.URL.createObjectURL(new Blob([blob], { type: 'application/octet-stream' }));
          const streamLink = document.createElement('a');
          streamLink.href = streamURL;
          streamLink.setAttribute('download', docLabel);
          document.body.appendChild(streamLink);
          streamLink.click();
          setTimeout(() => {
            document.body.removeChild(streamLink);
            window.URL.revokeObjectURL(streamURL);
          }, 100);
        }
        if (!!docTypeFlagPopable === true) {
          const streamLink = document.createElement('a');
          streamLink.href = temporaryDownloadUrl;
          streamLink.setAttribute('download', docLabel);
          // if (docLabel.endsWith('.pdf'))

          streamLink.setAttribute('target', '_blank');
          document.body.appendChild(streamLink);
          streamLink.click();
          setTimeout(() => {
            document.body.removeChild(streamLink);
          }, 100);
        }
      };

      function iconFile(item) {
        switch (item) {
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.ms-excel':
            return <SvgDynamicIcon fillColor={green[900]} name="fileExcel" />
          case 'text/csv':
            return <SvgDynamicIcon fillColor={green[900]} name="fileCsv" />
          case 'image/jpeg':
          case 'image/jpg':
          case 'image/png':
            return <SvgDynamicIcon fillColor={blueGrey[500]} name="fileImage" />

          case 'application/pdf':
            return <SvgDynamicIcon fillColor={grey[50]} name="filePdf" />

          case 'application/zip':
            return <SvgDynamicIcon fillColor={grey[50]} name="fileZipper" />
          case 'application/xml':
          case 'text/xml':
            return <img src={Exabanque} width="20" color="primary" alt={'exabanqueLogo'} />
          default:
            return <SvgDynamicIcon fillColor={grey[50]} name="file" />

        }
      }
      if (payload && payload.docs && payload.docs.length > 0) {
        // let flagDirectDownload = payload.flagDirectDownload || false
        payload.docs.map((doc) => {
          // let {
          //   temporaryDownloadUrl,
          //   docId,
          //   docLabel,
          //   docSizeInByte,
          //   docTypeCode,
          //   docMimeType,
          //   docPath,
          //   docTypeFlagPopable = true,
          // } = doc;doc
          algo(doc);
          let enqueueSnackbarKey = enqueueSnackbar(message, {
            persist,
            variant: level,
            autoHideDuration,
            action: (
              <>
                <Tooltip key="1" TransitionComponent={Fade} title="Télécharger votre fichier" arrow>
                  <Button
                    sx={{ color: '#fff' }}
                    onClick={() => {
                      algo(doc, { forceFlagDownload: true });
                    }}
                    startIcon={iconFile(doc.docMimeType)}
                  >Télécharger le fichier</Button>
                </Tooltip>
                <Button
                  onClick={() => {
                    closeSnackbar(enqueueSnackbarKey);
                  }}
                >
                  <CloseRounded />
                </Button>
              </>
            ),
          });
        });
      }
      if (message !== '' && payload && !payload.docs) {
        let enqueueSnackbarKey = enqueueSnackbar(message, {
          persist,
          variant: level,
          autoHideDuration,
          action: (
              <>
                <Button onClick={() => closeSnackbar(enqueueSnackbarKey)}><CloseRounded color="secondary"/></Button>
              </>
          ),
        });
      }
    }
    )} else {
      window.location.href = config.front.logout.url
    }
  }
  return null;
};

export default SocketClient;
