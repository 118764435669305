import config from 'config.js';
import { CheckPermissionUtil } from 'components/KMCan/CheckPermission';
import { CheckRoleUtil } from 'components/KMCan/CheckRole';
import TemporaryDrawer from 'components/navigation/TemporaryDrawer';
import TemporaryDialog from 'components/navigation/TemporaryDialog';
import { Suspense, useMemo } from 'react';
import { lazy, useContext, useState } from 'react';
import { KMInfraContext } from 'stores/KMInfraProvider';
import { KMSessionUserContext } from 'stores/KMSessionUserProvider';
import { PDrawerContext } from 'stores/PDrawerProvider';
import PFormProvider from 'stores/PFormProvider';

const DisplayLayerFromDrawerStore = (props) => {
  const { sessionUserStore, sessionUserStoreRightPermissionDenied } = useContext(KMSessionUserContext);
  let { drawerStore, removeLayer, updateLayer, removeEveryLayer } = useContext(PDrawerContext);
  const { infraStore } = useContext(KMInfraContext);

  const myConfig = config; // ne pas EFFACER ! eval
  const privateRoutes = infraStore.privateRoutes;
  return (
    <>
      {drawerStore.layers.map((layer) => {
        const {
          layerRoutePath,
          layerCallbackOnClose,
          layerCallbackOnAction,
          layerPrevNextIds,
          hiddenFieldIds,
          layerType = 'temporaryDrawer',
          handleChooseListTableLine,
        } = layer;
        let params = {};
        let LazyComponent = null;
        let myHiddenFieldType = null;
        let ErrorBoundarieComponent = null;
        let previousRoute = null;
        let nextRoute = null;
        let previousIndex = null; // for list table selection line need index 1 is for first line 2 for second etc...
        let nextIndex = null; // for list table selection line need index 1 is for first line 2 for second etc...
        // let privateRoutePath = null
        let myRouteDrawerLabel = '';
        let myOriginalRoutePath = '';
        let myApiList = null;
        let myDetailPanelReactComponent = null;
        let myDetailPanelReactComponentProp = null;

        let myFormCode = null;
        let myComputeWhenChanges = [];
        let myFilterCode = null;
        let myReportCode = null;
        let myStaticNavigations = null;
        let myFlagNoBehaviorRenderCustomDrawerTitle = null;
        let myStaticTabNavigation = null;
        let myStaticHelp = null;
        let myDetailPanelExpandIcon = null;
        let myDetailPanelCollapseIcon = null;
        let myInitialSteps = null;

        let myRouteLabel = null;
        let myRouteCallbackOnAction = null;
        let myLayerFullWidth = null;
        let myRouteEntityIdName = null
        let myRouteReactComponent = null

        let myComponentProps = null

        privateRoutes
          .sort(
            (privateLayoutRouteCurrent, privateLayoutRouteNext) =>
              privateLayoutRouteCurrent.routeOrder - privateLayoutRouteNext.routeOrder
          )
          .filter((privateLayoutRoute) => privateLayoutRoute.routeReactComponent)
          .map((privateRoute) => {
            let {
              routeId,
              routeListRBAC,
              routePath,
              routeJSONParam,
              routePathPattern,
              routeDrawerLabel = '',
              routeLabel,
              routeEntityIdName,
              routeReactComponent
            } = privateRoute;
            try {
              routePathPattern = new RegExp(routePathPattern);
              // if (routeId == 693
              //     && layerRoutePath == "/chooseAgent/treatment/document/formSEPADirectDebitMANDATE"
              // ) debugger

              if (routePathPattern && routePathPattern.test(layerRoutePath) === true) {
              } else return null;
            } catch (error) {
              // debugger
            }
            // privateRoutePath = routePath
            let canView = CheckPermissionUtil({
              sessionUserStore,
              perform: routeListRBAC,
              debug: false,
            });
            try {
              routeJSONParam = JSON.parse(routeJSONParam);
              // config.api.inquiry.listItemSavingToTreat.url)
            } catch (error) {
              console.log(error);
            }
            let {
              apiList,
              detailPanelReactComponent,
              detailPanelReactComponentProp,
              formCode,
              computeWhenChanges = [],
              routeCallbackOnAction,
              layerFullWidth,
              flagNoBehaviorRenderCustomDrawerTitle = false,
              hiddenFieldType,
              filterCode,
              reportCode = '',
              staticNavigations = [],
              staticTabNavigation = '',
              staticHelp = '',
              detailPanelExpandIcon = '',
              detailPanelCollapseIcon = '',
              initialSteps = [],
              componentProps = {},
            } = routeJSONParam || {};
            try {
              if (apiList) apiList = eval('myConfig.api.' + apiList + '.url');
            } catch (error) {
              console.log('missconfiguration of config file. see : ', routePath, routeJSONParam, error);
            }

            myApiList = apiList;
            myDetailPanelReactComponent = detailPanelReactComponent;
            myDetailPanelReactComponentProp = detailPanelReactComponentProp;


            myHiddenFieldType = hiddenFieldType;
            myFormCode = formCode;
            myComputeWhenChanges = computeWhenChanges;

            myFilterCode = filterCode;
            myReportCode = reportCode;
            myStaticNavigations = staticNavigations;
            myFlagNoBehaviorRenderCustomDrawerTitle = flagNoBehaviorRenderCustomDrawerTitle
            myStaticTabNavigation = staticTabNavigation;
            myStaticHelp = staticHelp;
            myDetailPanelExpandIcon = detailPanelExpandIcon;
            myDetailPanelCollapseIcon = detailPanelCollapseIcon;

            myInitialSteps = initialSteps;

            myRouteCallbackOnAction = routeCallbackOnAction;
            myLayerFullWidth = layerFullWidth;

            myRouteEntityIdName = routeEntityIdName;
            myRouteReactComponent = routeReactComponent;


            myRouteLabel = routeLabel;
            myOriginalRoutePath = routePath;

            myComponentProps = componentProps;
            let routeRBACs =
              routeListRBAC && routeListRBAC.indexOf(',') > 0
                ? routeListRBAC.split(',').map((rbac) => rbac.trim())
                : [routeListRBAC];
            if (!!canView === false) {
              canView = CheckRoleUtil({
                sessionUserStore,
                allowedRoles: routeRBACs,
                debug: false,
              });
            }
            if (!!canView === false) {
              ErrorBoundarieComponent = <div>droit manquant {routeListRBAC}</div>;
              return null;
            }
            // extract variable by finding number of ":"
            let paramNames = routePath.match(/:[a-zA-Z]+/g) || [];
            // back reference pattern to extract params dynamicly
            paramNames.map((paramName, position) => {
              let paramValue = layerRoutePath.replace(routePathPattern, '$' + (position + 1));
              params[paramName.replace(':', '')] = paramValue;
              if (position === 0 && layerPrevNextIds) {
                // entité primaire
                // recherche de la navigation précédente et suivante
                const indexEnCours = layerPrevNextIds.indexOf(parseInt(paramValue));
                let previousId = indexEnCours > 0 ? layerPrevNextIds[indexEnCours - 1] : null;
                let nextId = indexEnCours < layerPrevNextIds.length ? layerPrevNextIds[indexEnCours + 1] : null;
                if (previousId) {
                  previousRoute = routePath.replace(paramName, previousId);
                  previousIndex = layerPrevNextIds.findIndex((layerPrevNextId) => layerPrevNextId === previousId);
                }
                if (nextId) {
                  nextIndex = layerPrevNextIds.findIndex((layerPrevNextId) => layerPrevNextId === nextId);
                  nextRoute = routePath.replace(paramName, nextId);
                }
              }
              return paramName;
            });
            myRouteDrawerLabel = routeDrawerLabel
              ? routeDrawerLabel.replace(/___entityId___/, params && params['entityId'] ? params['entityId'] : '')
              : '';
            LazyComponent = lazy(() => import(`views/${routeReactComponent}`));
            console.log('layer route:' + layerRoutePath, 'layer component:' + routeReactComponent);
            return null;
          });
        if (!LazyComponent) {
          // si le layer n'a pas de composant nous retirons le layer du store pour bien redimensionner les prochains eligible
          removeLayer(layer);
          // removeEveryLayer(layer);
          return null;
        }
        const handleOnClose = () => {
          removeLayer(layer);
          if (layerCallbackOnClose) layerCallbackOnClose();
          // if (layerCallbackOnClose) removeEveryLayer();
          // let lastLayer = drawerStore.layers[layers.length - 1] || {}
          // history.push(lastLayer.layerRoutePath || "")
          // history.push("")
        };
        return (
          <MemoizedDrawerComponent
            key={myRouteDrawerLabel}
            layerType={layerType}
            LazyComponent={LazyComponent}
            handleOnClose={handleOnClose}
            layer={layer}
            myRouteDrawerLabel={myRouteDrawerLabel}
            previousRoute={previousRoute}
            nextRoute={nextRoute}
            handleChooseListTableLine={handleChooseListTableLine}
            updateLayer={updateLayer}
            previousIndex={previousIndex}
            nextIndex={nextIndex}
            layerRoutePath={layerRoutePath}
            originalRoutePath={myOriginalRoutePath}
            ErrorBoundarieComponent={ErrorBoundarieComponent}
            params={params}
            layerCallbackOnAction={layerCallbackOnAction}
            hiddenFieldIds={hiddenFieldIds}
            myHiddenFieldType={myHiddenFieldType}
            myApiList={myApiList}
            myDetailPanelReactComponent={myDetailPanelReactComponent}
            myDetailPanelReactComponentProp={myDetailPanelReactComponentProp}

            myFormCode={myFormCode}
            myComputeWhenChanges={myComputeWhenChanges}
            myFilterCode={myFilterCode}
            myReportCode={myReportCode}
            myStaticNavigations={myStaticNavigations}
            myFlagNoBehaviorRenderCustomDrawerTitle={myFlagNoBehaviorRenderCustomDrawerTitle}
            myStaticTabNavigation={myStaticTabNavigation}
            myStaticHelp={myStaticHelp}
            myDetailPanelExpandIcon={myDetailPanelExpandIcon}
            myDetailPanelCollapseIcon={myDetailPanelCollapseIcon}

            myInitialSteps={myInitialSteps}
            myRouteLabel={myRouteLabel}
            myRouteCallbackOnAction={myRouteCallbackOnAction}
            myLayerFullWidth={myLayerFullWidth}

            myRouteEntityIdName={myRouteEntityIdName}
            myRouteReactComponent={myRouteReactComponent}
            sessionUserStore={sessionUserStore}

            myComponentProps={myComponentProps}
          />
        );
      })}
    </>
  );
};

const MemoizedDrawerComponent = (props) => {
  let {
    myFormCode,
    layerType,
    LazyComponent,
    handleOnClose,
    layer,
    myRouteDrawerLabel,
    previousRoute,
    nextRoute,
    handleChooseListTableLine,
    updateLayer,
    previousIndex,
    nextIndex,
    layerRoutePath,
    originalRoutePath,
    ErrorBoundarieComponent,
    params,
    layerCallbackOnAction,
    hiddenFieldIds,
    myHiddenFieldType,
    myApiList,
    myDetailPanelReactComponent,
    myDetailPanelReactComponentProp,
    myComputeWhenChanges,
    myFilterCode,
    myReportCode,
    myStaticNavigations,
    myFlagNoBehaviorRenderCustomDrawerTitle,
    myStaticTabNavigation,
    myStaticHelp,
    myDetailPanelExpandIcon,
    myDetailPanelCollapseIcon,
    myInitialSteps,
    myRouteLabel,
    myRouteCallbackOnAction,
    myLayerFullWidth,
    myRouteEntityIdName,
    myRouteReactComponent,
    sessionUserStore,
    myComponentProps,
  } = props;
  let Temporary = layerType === 'temporaryDrawer' ? TemporaryDrawer : TemporaryDialog;
  const [customDrawerTitle, setCustomDrawerTitle] = useState(myRouteDrawerLabel)
  return useMemo(
    () => (
      <PFormProvider formCode={myFormCode}>
        <Temporary
          handleOnClose={handleOnClose}
          layer={layer}
          drawerTitle={customDrawerTitle}
          flagHasPreviousRoute={!!previousRoute}
          flagHasNextRoute={!!nextRoute}
          previousRoute={() => {
            if (handleChooseListTableLine) handleChooseListTableLine(previousIndex);
            updateLayer({ layer, newLayerRoutePath: previousRoute });
          }}
          nextRoute={() => {
            if (handleChooseListTableLine) handleChooseListTableLine(nextIndex);
            updateLayer({ layer, newLayerRoutePath: nextRoute });
          }}
        >
          {!!config.app.debugMode === true && <><br /><b>layerRoutePath:</b>{layerRoutePath}</>}
          {!!config.app.debugMode === true && <><br /><b>originalRoutePath:</b>{originalRoutePath}</>}
          {!!config.app.debugMode === true && <><br /><b>RouteEntityIdName:</b>{myRouteEntityIdName}</>}
          {!!config.app.debugMode === true && <><br /><b>RouteReactComponent:</b>{myRouteReactComponent}</>}

          {ErrorBoundarieComponent ? (
            { ErrorBoundarieComponent }
          ) : (
            <Suspense fallback={
              <>
                {/*<Skeleton />*/}
                {/*<Skeleton animation="wave" />*/}
                {/*<Skeleton animation={false} />*/}
              </>
            }>
              <LazyComponent
                match={{ params }} // req.params
                layerCallbackOnAction={layerCallbackOnAction} //
                drawerFlagMode={true} // flag pour savoir si le composant est en drawermode
                hiddenFieldIds={hiddenFieldIds}
                hiddenFieldType={myHiddenFieldType}
                apiList={myApiList} // injection provenant de la table route apiList
                detailPanelReactComponent={myDetailPanelReactComponent} // injection provenant de la table route detailPanelReactComponent permet de choisir quel composant de type item ouvert par le material-table
                detailPanelReactComponentProp={myDetailPanelReactComponentProp} // injection provenant de la tale route detailPanelReactComponentProp permet de sepecifier des variations au composant item d'une material-table
                formCode={myFormCode} // injection provenant de la table route formCode
                computeWhenChanges={myComputeWhenChanges} // injection provenant de la table route computeWhenChanges
                filterCode={myFilterCode} // injection provenant de la table route filterCode
                reportCode={myReportCode} // injection provenant de la table route reportCode
                staticNavigations={myStaticNavigations}
                myFlagNoBehaviorRenderCustomDrawerTitle = {myFlagNoBehaviorRenderCustomDrawerTitle}
                staticTabNavigation={myStaticTabNavigation}
                staticHelp={myStaticHelp}
                detailPanelExpandIcon={myDetailPanelExpandIcon}
                myDetailPanelCollapseIcon={myDetailPanelCollapseIcon}
                initialSteps={myInitialSteps}
                routeLabel={myRouteLabel} // injection provenant de la table route routeLabel
                routeCallbackOnAction={myRouteCallbackOnAction} // injection provenant de la table route routeCallbackOnAction
                layerFullWidth={myLayerFullWidth} // injection du beahavior layerFullWidth qui permet d'ouvir un drawer en fullwidth
                routeEntityIdName={myRouteEntityIdName}
                routeReactComponent={myRouteReactComponent}
                setCustomDrawerTitle={setCustomDrawerTitle} // permet de modifier le drawer Title à la volée
                sessionUserStore={sessionUserStore}
                componentProps={myComponentProps}
              />
            </Suspense>
          )}
        </Temporary>
      </PFormProvider>
    ),
    [layerRoutePath, myFormCode, customDrawerTitle]
  );
};
export default DisplayLayerFromDrawerStore;
