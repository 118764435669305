import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { motion } from "framer-motion";

const Wrapper = (props) => {
    return (
    <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{
            duration: 0.2,
            delay: 0.005,
            damping: 60,
            stiffness: 0
        }}
    >
        {props.children}
    </motion.div>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabfactory-tabpanel-${index}`}
            aria-labelledby={`tabfactory-tab-${index}`}
            {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

export default function BasicTabs({ defaultValue, tabs }) {
    const [value, setValue] = React.useState(defaultValue ^ 0); // useGivenValue or select the first one

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
            <Box  sx={{ width: "100%" }}>
                <Box >
                    <Tabs
                        sx={{"& .Mui-selected": {
                            color: "primary"
                        },
                        "& .MuiTabs-indicator": {
                            backgroundColor: "primary"
                        }}}
                        value={value}
                        onChange={handleChange}
                        aria-label="TabFactory"
                        variant="fullWidth"
                    >
                    {tabs.map(({ label }, i) => (
                        <Tab label={label} key={i} />
                    ))}
                    </Tabs>
                </Box>
                {tabs.map(({ Component }, i) => (
                    <TabPanel value={value} index={i} key={i}>
                            <Wrapper key={i}>
                                {Component}
                            </Wrapper>
                    </TabPanel>
                ))}
            </Box> 
    );
}
