import PropTypes from 'prop-types';
import React from 'react';

// icon FontAwesome
import { ReactComponent as fileCsv } from 'assets/icons/file-csv-solid.svg';
import { ReactComponent as filePdf } from 'assets/icons/file-pdf-solid.svg';
import { ReactComponent as fileZipper } from 'assets/icons/file-zipper-solid.svg';
import { ReactComponent as fileExcel } from 'assets/icons/file-excel-solid.svg';
import { ReactComponent as fileWord } from 'assets/icons/file-word-regular.svg';
import { ReactComponent as fileWordSolid } from 'assets/icons/file-word-solid.svg';
import { ReactComponent as fileImage } from 'assets/icons/file-image-solid.svg';
import { ReactComponent as file } from 'assets/icons/file-solid.svg';
import { ReactComponent as asterisk } from 'assets/icons/asterisk-solid.svg';
import { ReactComponent as calendarDay } from 'assets/icons/calendar-day-solid.svg';
import { ReactComponent as clock } from 'assets/icons/clock-solid.svg';
import { ReactComponent as euroSign } from 'assets/icons/euro-sign-solid.svg';
import { ReactComponent as fileCirclePlus } from 'assets/icons/file-circle-plus-solid.svg';
import { ReactComponent as handshake } from 'assets/icons/handshake-solid.svg';
import { ReactComponent as handshakeRegular } from 'assets/icons/handshake-regular.svg';
import { ReactComponent as hourglassStart } from 'assets/icons/hourglass-start-solid.svg';
import { ReactComponent as idCardClip } from 'assets/icons/id-card-clip-solid.svg';
import { ReactComponent as magnifyingGlass } from 'assets/icons/magnifying-glass-solid.svg';
import { ReactComponent as piggyBank } from 'assets/icons/piggy-bank-solid.svg';
import { ReactComponent as rotate } from 'assets/icons/rotate-solid.svg';
import { ReactComponent as starOfLife } from 'assets/icons/star-of-life-solid.svg';
import { ReactComponent as trashCan } from 'assets/icons/trash-can-solid.svg';
import { ReactComponent as trash } from 'assets/icons/trash-solid.svg';
import { ReactComponent as userGroup } from 'assets/icons/user-group-solid.svg';
import { ReactComponent as fileLines } from 'assets/icons/file-lines-solid.svg';
import { ReactComponent as handshakeAngle } from 'assets/icons/handshake-angle-solid.svg';
import { ReactComponent as handshakeSimple } from 'assets/icons/handshake-simple-solid.svg';

// Icon perso
import { ReactComponent as mergePdf } from 'assets/icons/merge_pdf.svg';
import { ReactComponent as xlsPdf } from 'assets/icons/xls_pdf.svg';
import { ReactComponent as moneyCheckEuroSign } from 'assets/icons/money-check-euro-solid.svg';
import { ReactComponent as printUser } from 'assets/icons/printUser.svg';



const iconTypes = {
  file,
  clock,
  trash,
  rotate,
  xlsPdf,
  filePdf,
  printUser,
  fileCsv,
  asterisk,
  euroSign,
  trashCan,
  mergePdf,
  fileExcel,
  fileWord,
  fileWordSolid,
  userGroup,
  fileImage,
  handshake,
  piggyBank,
  fileLines,
  fileZipper,
  idCardClip,
  starOfLife,
  calendarDay,
  handshakeAngle,
  handshakeSimple,
  fileCirclePlus,
  hourglassStart,
  magnifyingGlass,
  handshakeRegular,
  moneyCheckEuroSign
};

const sizePixelConversion = (size) => {
  switch (size) {
    case '2xs':
      return '10px';
    case 'xs':
      return '12px';
    case 'sm':
      return '14px';
    case 'lg':
      return '20px';
    case 'xl':
      return '24px';
    case '2xl':
      return '32px';
    default:
      return '24px'

  }
}

const SvgDynamicIcon = ({ name, size, fillColor, stroke,grayscaleColor, ...props }) => {
  let IconSvgPerso = iconTypes[name];
  return <IconSvgPerso
    style={{ width: sizePixelConversion(size), fill: fillColor, filter: `grayscale(${grayscaleColor})`, stroke: stroke }} {...props} />;
};
SvgDynamicIcon.defaultProps = {
  size: 'sm',
  fillColor: '#000',
  stroke: '',
  grayscaleColor: '0' // 0 = normal - 1 = gray -> valide par exemple 0.20 ou 20%
};
SvgDynamicIcon.propTypes = {
  size: PropTypes.string,
  fillColor: PropTypes.string,
  stroke: PropTypes.string,
  grayscaleColor: PropTypes.string
};
export { SvgDynamicIcon };
