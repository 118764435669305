import { useEffect } from "react"
import { useParams } from "react-router-dom";
// set le JWT et redirige l'utilisateur
const Autolog = () => {
  let { token } = useParams(); 
  useEffect(() => {
    let stopRedirect = window.localStorage.getItem('stopRedirect') || false
    if (!!stopRedirect === true) debugger
    window.localStorage.setItem('JWT', token) // le jwt est mis en session sur le domaine de l'admin

    let redirectToReferrerStr = window.localStorage.getItem('redirectToReferrer') // si la personne n'etait pas connecté nous avons stocké l'endroit ou il souhaitait aller
    let from = '/dashboard' // par défaut on le redirige sur la home
    if (redirectToReferrerStr) {
      try {
        let redirectToReferrer = JSON.parse(redirectToReferrerStr)
        let patternNoRedirectLoop = /^\/logout/i // evite les boucles infinies
        if (!redirectToReferrer.pathname.match(patternNoRedirectLoop))
          from = redirectToReferrer.pathname + (redirectToReferrer.search ? redirectToReferrer.search : '')
      } catch (exception) { }
    }
    if (stopRedirect === false) window.location = from // on redirige l'utilisateur
  }, [])
  return null
}

export default Autolog
