const config = {
	app: {
		serviceName: `${process.env.REACT_APP_APP_SUBDOMAIN || "callWF"}.${process.env.REACT_APP_MAIN_DOMAIN_NAME || "keepaticket.keepapi.app"
			}`,
		adminUrlPublic: process.env.ADMIN_URL_PUBLIC || "https://local-admin.keepaticket.keepapi.app",
		apiUrlPublic: process.env.REACT_APP_API_URL_PUBLIC,
		env: process.env.REACT_APP_ENV || "local",
		debugMode: process.env.REACT_APP_DEBUG_MODE || !!window.localStorage.getItem('debugMode') === true || false,
	},
	front: {
		logout: {
			url: `${process.env.REACT_APP_FRONT_URL_PUBLIC}/logout`,
		},
	},
	api: {
		url: `${process.env.REACT_APP_API_URL_PUBLIC}/api`,
		rbacRules: {
			getRbacUser: {
				url: `${process.env.REACT_APP_API_URL_PUBLIC}/api/rbacRules/getRbacUser`,
			},
		},
		session: {
			user: {
				url: `${process.env.REACT_APP_API_URL_PUBLIC}/api/session/user`,
			},
		},
		public: {
			infra: {
				react: {
					url: `${process.env.REACT_APP_API_URL_PUBLIC}/api/infra/react`,
				},
			},
		},
	},
	pf: {
		url: `${process.env.REACT_APP_PF_URL_PUBLIC}`,
	},
	oauth: {
		logout: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/logout`,
		},
		login: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/login`,
		},
		register: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/register`,
		},
		isAuthenticated: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/isAuthenticated`,
		},
		lastAliveDatetime: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/lastAliveDatetime`,
		},
	},
};

export default config;
