
import { useEffect, useState } from "react"
import { redirect, Route, Routes } from 'react-router-dom';
import { oauth } from 'oauth/oauth'
import PrivateLayout from 'layouts/PrivateLayout'
import React from "react";
import { motion } from "framer-motion";

const PrivateRoute = (props) => {
    let [trust, setTrust] = useState('red') // pas encore fetcher on ne passe pas
    useEffect(() => { openPrivateRoute() }, [])
    const openPrivateRoute = async () => {
        const isAuthenticated = await oauth.authenticate()
        if (isAuthenticated === true) setTrust('green')
        else setTrust('orange')
    }
    if (trust === 'red') return null
    if (trust === 'orange') {
        // redirection vers le logout et passage de l'url voulue par l'utilisateur
        return (
            redirect('/logout')
        )
    }
    // la personne est connectée on lance le layout admin
    if (trust === 'green')
        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={{
                    initial: {
                        opacity: 0,
                    },
                    in: {
                        opacity: 1,
                    },
                    out: {
                        opacity: 0,
                    },
                }}
                transition={{
                    duration: 0.5,
                    delay: 0.005,
                }}
            >
                <PrivateLayout {...props} />
            </motion.div>
        )
}
export default PrivateRoute
