import dayjs from 'dayjs'
import 'dayjs/locale/fr';
import checkNir from 'utils/checkNIR'
import { IBAN, BIC } from "ibankit";
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isBoolean from 'validator/lib/isBoolean';
import isInt from 'validator/lib/isInt';
import matches from 'validator/lib/matches';
import isPostalCode from 'validator/lib/isPostalCode';
import isFloat from 'validator/lib/isFloat';


const checkFieldStore = ({ key, value, referencial, errorStore, store } = {}) => {
    let noError = true
    if (key in referencial) {
        let { type, pattern, patternFlag = '', otherFieldsToChecks, testOnlyIf, testOnlyPattern, nullable, acceptEmptyValue, structChecks, validatorOption = {} } = referencial[key].rule
        // test seulement la regle si elle a une dependence avec un boolean qui repond à ce pattern testOnlyPattern
        if (testOnlyIf && testOnlyPattern) {
            testOnlyPattern = new RegExp(testOnlyPattern)
            if (!!store[testOnlyIf]) {
                let testOnlyIfValue = store[testOnlyIf]
                let testOnlyIfType = referencial[testOnlyIf].rule.type
                // on cast uniquement si la string n'est pas déjà egale à "true" ou à "false"
                if (testOnlyIfType === 'isBoolean' && !["true", "false"].includes(testOnlyIfValue)) testOnlyIfValue = !!testOnlyIfValue + '' // cast le boolean en String => "true" ou en "false"
                if (testOnlyIfType === 'isBoolean' && !testOnlyIfValue.match(testOnlyPattern)) {
                    delete errorStore[key]
                    return errorStore
                }
            }
        }
        if (type === 'oldNewPassord') {
            if (((!!!store['newUserPassword'] || store['newUserPassword'] === "") || (!!!store['oldUserPassword'] || store['oldUserPassword'] === "")) && store['oldUserPassword'] === "") {
                delete errorStore['newUserPassword']
                delete errorStore['oldUserPassword']
            }
            else if ((!!!store['newUserPassword'] || store['newUserPassword'] === "") || (!!!store['oldUserPassword'] || store['oldUserPassword'] === "")) {
                delete errorStore['newUserPassword']
                delete errorStore['oldUserPassword']
            }
            else if (store['newUserPassword'] !== store['oldUserPassword']) {
                errorStore["newUserPassword"] = referencial['newUserPassword']
                errorStore["oldUserPassword"] = referencial['oldUserPassword']
            }
            else if (store['newUserPassword'] && store['newUserPassword'].length === 0) {
                delete errorStore['newUserPassword']
                delete errorStore['oldUserPassword']
            } else {
                pattern = new RegExp(pattern, patternFlag || '')
                if (
                    typeof (store['newUserPassword']) !== 'undefined'
                    && typeof (store['newUserPassword']) === 'string'
                    &&
                    matches(store['newUserPassword'], pattern) === true
                ) {
                    delete errorStore['newUserPassword']
                    delete errorStore['oldUserPassword']
                }
                else {
                    errorStore["newUserPassword"] = referencial['newUserPassword']
                    errorStore["oldUserPassword"] = referencial['oldUserPassword']
                }
            }

        }
        else if (!!nullable === false && value === null) {
            errorStore[key] = referencial[key]
        }
        else if (!!nullable === true && (value === null || value === undefined)) {
            delete errorStore[key]
        }
        else if (!!acceptEmptyValue === true && typeof (value) === 'string' && value === "") {
            delete errorStore[key]
        }
        // else if (acceptEmptyValue === true && typeof(value) ==='string' && value === "") {
        //     delete errorStore[key]
        // }

        else if (type === 'pattern') {
            pattern = new RegExp(pattern, patternFlag || '')
            value = value + '' // cast en string
            if (
                typeof (value) !== 'undefined'
                && typeof (value) === 'string'
                &&
                matches(value, pattern) === true
            )
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
        }

        else if (type === 'isBoolean') {
            value = !!value + '' // cast le boolean en String => "true" ou en "false"
            if (isBoolean(value, validatorOption || {}) === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
            // check other fields if referenciel says so
            if (otherFieldsToChecks) {
                otherFieldsToChecks.map((otherFieldToCheck) => {
                    store[key] = value // mutation du store à la volé
                    errorStore = checkFieldStore({ key: otherFieldToCheck, value: store[otherFieldToCheck], referencial, errorStore, store })
                })
            }
        }
        else if (type === 'isPostalCode') {
            value = value + '' // cast le number en string
            if (isPostalCode(value, "any") === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]

        }
        else if (type === 'isAlpha') {
            value = value + '' // cast le number en string
            if (isInt(value, ['fr-CA', 'fr-FR'], validatorOption) === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
        }
        else if (type === 'isFloat') {
            value = value + '' // cast le number en string
            value = value.replace(',', '.')
            if (isFloat(value, validatorOption) === true) {

                delete errorStore[key]
            }
            else {

                errorStore[key] = referencial[key]
            }
        }
        else if (type === 'isInt') {
            value = value + '' // cast le number en string
            if (isInt(value, validatorOption) === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
        }
        else if (type === 'isEmail') {
            value = value + '' // cast to string
            if (isEmail(value) === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
        }
        else if (type === 'isMobilePhone') {
            value = value + '' // cast to string
            value = value.replaceAll(' ', '')
            if (isMobilePhone(value, ['fr-BE', 'fr-BF', 'fr-FR', 'fr-GF', 'fr-GP', 'fr-MQ', 'fr-PF', 'fr-RE']) === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
        }
        else if (type === 'NIR') {
            value = value + '' // cast to string
            if (checkNir(value) === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
        }
        else if (type === 'iban') {
            value = value + '' // cast to string
            if (IBAN.isValid(value) === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
        }
        else if (type === 'bic') {
            value = value + '' // cast to string
            if (BIC.isValid(value) === true)
                delete errorStore[key]
            else
                errorStore[key] = referencial[key]
        }
        else if (type === 'dayjs') {
            // value = value + '' // cast to string
            if (value === null) {
                errorStore[key] = referencial[key]
            }
            value = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
            if (!dayjs(value).isValid()) {
                errorStore[key] = referencial[key]
            } else
                delete errorStore[key]
        }
        else if (type === 'array') {
            if (!!acceptEmptyValue === false) {
                value = Array.isArray(value) ? value : [value] // cast to string
                value = value.filter(val => val)
                if (value.length === 0)
                    errorStore[key] = referencial[key]
                else
                    delete errorStore[key]
            }
            else
                delete errorStore[key]
        }

        else if (value && type === 'complexArray') {
            let myValues = value.filter((recommendation, index) => {
                return false
            })
            if (myValues.length > 0)
                errorStore[key] = myValues
            else
                delete errorStore[key]
        }

        else if (value && type === 'struct') {
            let myValues = structChecks.filter(structCheck => {
                const { type, field, pattern, otherFieldsToChecks, testOnlyIf, testOnlyPattern, nullable, acceptEmptyValue, structChecks } = structCheck
                let _tempValue = value[field]
                let makeSomeMatch = true
                if (_tempValue === null && nullable === false) {
                    noError = false
                    makeSomeMatch = false
                } else if (_tempValue === '' && acceptEmptyValue === true) {
                    makeSomeMatch = false
                }
                if (type === 'boolean') _tempValue = !!_tempValue + '' // cast to "false" or "true" string type for convenience
                if (type === 'boolean') debugger
                if (type === 'number') _tempValue = _tempValue + '' // cast to "number" string type for convenience*

                if (noError === true && makeSomeMatch === true && _tempValue !== null && pattern !== null && !_tempValue.match(pattern)) {
                    noError = false
                }

                return !noError
            })
            if (myValues.length === 0) delete errorStore[key]
            else {
                errorStore[key] = referencial[key]
            }
        }
        else {
            pattern = new RegExp(pattern, patternFlag || '')
            let makeSomeMatch = true
            if (value === null && nullable === false) {
                noError = false
                makeSomeMatch = false
            } else if (value === '' && acceptEmptyValue === true) {
                makeSomeMatch = false
            }
            if (type === 'boolean') value = !!value + '' // cast to "false" or "true" string type for convenience
            if (type === 'number') value = value + '' // cast to "number" string type for convenience

            if (noError === true
                && makeSomeMatch === true
                && typeof (value) !== 'undefined'
                && typeof (value) === 'string'
                && value !== null
                && pattern !== null
                && !value.match(pattern)
            ) {
                noError = false
            }
            if (noError === true) delete errorStore[key]
            else {
                errorStore[key] = referencial[key]
            }
        }
    }
    return errorStore
}
export default checkFieldStore