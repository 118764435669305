import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
}))


const PTooltip = (props) => {
  const {classes} = useStyles()
  const { noLogo = false, title } = props
  if (noLogo === true)
    return <Tooltip {...props} title={title} />
  else
    return (
      <Tooltip {...props}
        title={
          <Grid container alignItems="center" justifyItems={"space-between"} spacing={2}>
            <Grid item xs={9} ml={1}>
              <Typography variant="body2">{title}
              </Typography>
            </Grid>
          </Grid>
        }
      >
      </Tooltip>
    )
}
export { PTooltip }