import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { KMInfraContext } from 'stores/KMInfraProvider'
export const PDrawerContext = createContext({
    drawerStore: {
        layers: []
    }
})
const PDrawerProvider = (props) => {
    const { infraStore } = useContext(KMInfraContext)
    useEffect(() => {
        setDrawerStoreRightPermissionDenied(false)
    }, [])
    const privateRoutes = infraStore.privateRoutes

    const location = useLocation()
    const [drawerStore, setDrawerStore] = useState({
        layers: []
    })
    const reOpenDrawerStore = () => {
        privateRoutes.map(privateRoute => {
            let { routePathPattern } = privateRoute
            routePathPattern = new RegExp(routePathPattern)
            if (routePathPattern && routePathPattern.test(location.hash.replace("#", "")) === true) {
                addLayer({ layerRoutePath: location.hash.replace("#", "") })
            }
            return null
        })
    }
    const [drawerStoreRightPermissionDenied, setDrawerStoreRightPermissionDenied] = useState(true)
    const addLayer = ({
        layerType = 'temporaryDrawer',
        layerTitle,
        layerAnchor = "right",
        layerRoutePath,
        layerCallbackOnClose,
        layerCallbackOnAction,
        layerPrevNextIds,
        hiddenFieldIds,
        hiddenFieldType,
        layerFullWidth = false,
        handleChooseListTableLine // c'est un handler fourni par ListTable pour pouvoir choisir la ligne en cours
    } = {}) => {
        console.log("nous allons vers " + layerRoutePath)
        let layers = drawerStore.layers
        layers.push({
            layerTitle,
            layerType,
            layerAnchor,
            layerIndex: layers.length + 1,
            layerRoutePath,
            layerCallbackOnClose,
            layerCallbackOnAction,
            layerPrevNextIds,
            hiddenFieldIds,
            hiddenFieldType,
            handleChooseListTableLine,
            layerFullWidth
        })
        setDrawerStore({ layers })
        return layers.length
    }

    const removeLayer = ({ layer } = {}) => {
        let layers = drawerStore.layers
        if (layer) {
            layers.splice(layer.layerIndex, 1);
        } else {
            layers.pop()
        }
        setDrawerStore({ layers })
        return layers.length
    }

    const removeEveryLayer = ({ layer } = {}) => {
        let layers = drawerStore.layers
        if (layer) {
            layers.splice(layer.layerIndex, 1);
        } else {
            layers = []
        }
        setDrawerStore({ layers })
        return layers.length
    }

    const updateLayer = ({ layer, newLayerRoutePath = "" } = {}) => {
        let layers = drawerStore.layers
        if (layer)
            layers[layer.layerIndex - 1].layerRoutePath = newLayerRoutePath;
        setDrawerStore({ layers })
        return layers.length
    }
    // console.log("drawerStoredrawerStoredrawerStoredrawerStoredrawerStoredrawerStore",drawerStore)
    return (
        <PDrawerContext.Provider value={{ addLayer, removeLayer, removeEveryLayer, updateLayer, drawerStore, drawerStoreRightPermissionDenied, reOpenDrawerStore }}>
            {props.children}
        </PDrawerContext.Provider>
    )
}
export default PDrawerProvider
/*

  {drawerStore.layers.length > 0 &&
            <HashRouter>
                {drawerStore.layers.map((layer, index) =>
                    <HashNavigation
                        key={"HashNavigation_" + index}
                        layer={layer}
                        layers={drawerStore.layers}
                        updateLayer={updateLayer}
                        handleRemoveLayer={() => {
                            removeLayer(layer)
                        }}
                    />
                )}
            </HashRouter>
        }
        </>
        */