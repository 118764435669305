import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

export default function TemporaryDialog(props) {
    const { layer = {}, flagHasPreviousRoute, flagHasNextRoute, previousRoute, nextRoute, drawerTitle = "" } = props
    const {
        layerTitle = drawerTitle,
        layerAnchor = "right"
    } = layer

    let { handleOnClose, children } = props
    let [openDrawer, setOpenDrawer] = useState(true)
    const onClose = () => {
        if (handleOnClose) handleOnClose()
        setOpenDrawer(false)
        if (window.sessionStorage.getItem('refreshParentOnClose') && !!window.sessionStorage.getItem('refreshParentOnClose') === true) {
            window.sessionStorage.setItem('refreshParentOnClose', false)
        }
    }
    return (
        <div>

            <Dialog open={openDrawer} onClose={onClose} sx={{ marginTop: "5%"}}>
                <DialogTitle>    {layerTitle}</DialogTitle>
                <DialogContent>
                {children}
                </DialogContent>
             
            </Dialog>
        </div>
    );
}