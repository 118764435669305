import config from "config"
import { restIO } from 'utils/io'
import React, { createContext, useState } from 'react'
import { useSnackbar } from 'notistack';
import checkFieldStore from '../utils/checkFieldStore'
import { useEffect } from 'react';


export const PFormContext = createContext({
    formStore: {},
    formErrorStore: {},
    checkFieldFormStore: () => { },
    checkAllFieldFormStore: () => { },
    updateFormStore: () => { },
    reloadFormStore: () => { },
    initFormStore: () => { },
    formStoreAlreadyEditForms: [],
    initForCreationFormStore: () => { },
    formSequenceFields: [],
    formActions: [],
    formOption: {},
    backendMessage: {},
    forceErrorInStore: () => { },
    formEndPoint: ""
})
const PFormProvider = (props) => {
    let { formCode } = props
    const [referencial, setReferencial] = useState({})

    const [initForCreationFormStoreDefaultValue, setInitForCreationFormStoreDefaultValue] = useState({})
    const [formSequenceFields, setFormSequenceFields] = useState([])
    const [formActions, setFormActions] = useState([])
    const [backendMessage, setBackendMessage] = useState({}) // keepapi message from backend

    const { enqueueSnackbar } = useSnackbar()
    const [formStore, setFormStore] = useState({})
    const [formOption, setFormOption] = useState({})
    const [formErrorStore, setFormErrorStore] = useState({})
    const [formStoreRightPermissionDenied, setFormStoreRightPermissionDenied] = useState(true)
    const [formStoreAlreadyEditForms, setFormStoreAlreadyEditForms] = useState([]) // permet de savoir si l'utilisateur à modifier ou non une premiere fois un element étitable du store lié au référentiel. 
    const [formEndPoint] = useState(`${config.app.apiUrlPublic}/api/form${formCode}`)
    // lorsque le store est accessible nous "checkons" les erreurs
    useEffect(() => {
        if (formStoreRightPermissionDenied === false) {
            checkAllFieldFormStore()
        }
    }, [formStoreRightPermissionDenied])
    const forceErrorInStore = ({ forceKey, forceHelperText } = {}) => {
        if (!forceKey) return
        let errorStore = { ...formErrorStore }
        for (let key in referencial) {
            if (key === forceKey) {
                if (forceHelperText && forceHelperText !== '') {
                    referencial[key].helperText = forceHelperText
                }
                let myError = {}
                myError[key] = referencial[key]
                errorStore = { ...errorStore, ...myError }
            }
        }
        setFormErrorStore(errorStore)
    }
    const checkAllFieldFormStore = () => {
        let errorStore = { ...formErrorStore }
        let store = { ...formStore }
        for (let key in referencial)
            errorStore = checkFieldStore({
                key, value: store[key],
                errorStore,
                referencial,
                store,
                intenalRecursifFunction: checkFieldFormStore
            })
        setFormErrorStore(errorStore)
    }
    const checkFieldFormStore = (key, value) => {
        let errorStore = { ...formErrorStore }
        let store = { ...formStore }
        errorStore = checkFieldStore({ key, value, errorStore, referencial, store })
        setFormErrorStore(errorStore)
    }
    const updateFormStore = (key, value) => {
        let myFormStore = { ...formStore } // faster deep copy 
        myFormStore[key] = value
        if (key in referencial)
            myFormStore.toggle = !!!myFormStore.toggle
        setFormStore(myFormStore)
        checkFieldFormStore(key, value)
        let myFormStoreAlreadyEditForms = [...formStoreAlreadyEditForms]
        if (myFormStoreAlreadyEditForms.includes(key) !== true) {
            myFormStoreAlreadyEditForms.push(key)
            setFormStoreAlreadyEditForms(myFormStoreAlreadyEditForms)
        }
        // checkFieldCallback(key, value)
    }
    const initFormStore = async (entityId, flagWaitBeforeEnableFormStore = false, params = {}, routeEntityIdName = "entityId") => {
        setFormStoreAlreadyEditForms([])
        setFormStoreRightPermissionDenied(true)
        setFormErrorStore({})
        let endpoint = formEndPoint
        if (routeEntityIdName !== '') {
            endpoint = endpoint.replace(`:${routeEntityIdName}`, entityId)
        }
        for (let key in params) {
            endpoint = endpoint.replace(`:${key}`, params[key])
        }
        // if (routeEntityIdName !== '') {
        //     endpoint = endpoint.replace(`___${routeEntityIdName}___`, entityId)
        // }
        // for (let key in params) {
        //     endpoint = endpoint.replace(`___${key}___`, params[key])
        // }
        // debugger
        let payload = params || {}
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error, level = '', showBackendMessage = false, message = '' } = responseKeepapi
        setBackendMessage({ level, showBackendMessage, message })
        if (code !== 'ok') {
            enqueueSnackbar(`Votre demande ne peux aboutir. 202308171645`, { variant: 'warning', })
            return false
        }
        //  surchage des parametres d'url dans le bean
        if (datas.length > 1) {
            setFormStore({ formUUID: Math.random(), ...params, ...[datas] })
        }
        else if (datas.length > 0) {
            setFormStore({ formUUID: Math.random(), ...params, ...datas[0] })
        }
        else setFormStore({ formUUID: Math.random(), ...params })
        let { referencial, initForCreationFormStoreDefaultValue: initValue, sequenceFields, actions, ...rest } = extraData
        setReferencial(referencial)
        setInitForCreationFormStoreDefaultValue(initValue)
        setFormSequenceFields(sequenceFields)
        setFormActions(actions)
        setFormOption(rest)
        if (flagWaitBeforeEnableFormStore === false)
            setFormStoreRightPermissionDenied(false)
        return true
    }
    // @params hiddenField : capacité à passer des champs hiddens dans le formulaire ex {agentId:5,toto:[{titi:1}]}
    const initForCreationFormStore = async (hiddenField) => {
        setFormStore({ ...formStore, ...hiddenField, ...initForCreationFormStoreDefaultValue })
        setFormStoreRightPermissionDenied(false)
        return true
    }
    const reloadFormStore = (newEntityId, flagWaitBeforeEnableFormStore = false, params = {}, routeEntityIdName) => {
        setFormStoreRightPermissionDenied(true) // will force refresh :)
        initFormStore(newEntityId || formStore.entityId, flagWaitBeforeEnableFormStore, params, routeEntityIdName)
    }
    return (
        <PFormContext.Provider value={{
            updateFormStore,
            formStore,
            formStoreRightPermissionDenied,
            formErrorStore,
            checkFieldFormStore,
            checkAllFieldFormStore,
            reloadFormStore,
            initFormStore,
            formStoreAlreadyEditForms,
            initForCreationFormStore,
            formSequenceFields,
            formActions,
            formOption,
            backendMessage,
            forceErrorInStore,
            formEndPoint
        }}>
            {props.children}
        </PFormContext.Provider>
    )
}

export default PFormProvider