import { withSessionUserStore } from 'stores/KMSessionUserProvider'
const checkPermission = (perform = "", rbac = {}, data = null) => {
  let flagHasRight = true
  flagHasRight = flagHasRight === true && perform !== ""
  const permission = rbac
  flagHasRight = flagHasRight === true && !!permission
  // si flagHasRight n'est pas vrai alors il 'y a un probleme lors de l'appel
  if (!!flagHasRight === false) {
    return flagHasRight
  }
  // cherche les droits de type static
  const staticPermissions = permission.statics
  flagHasRight = flagHasRight === true && !!staticPermissions && staticPermissions.includes(perform)
  if (!!flagHasRight === true) {
    return flagHasRight
  }
  // cherche les droits de type dynamic
  const dynamicPermissions = permission.dynamics
  if (dynamicPermissions) {
    let flagHasPermission = false;
    dynamicPermissions.map(dynamicPermission => {
      if (perform in dynamicPermission) {
        const permissionCondition = dynamicPermission[perform]
        let tempFunction = eval(permissionCondition)
        flagHasPermission = typeof (tempFunction) === "function" ? tempFunction(data) : false
      }
      return dynamicPermission
    })
    return !!flagHasPermission
  }
  return false
}

const CheckPermission = props => {
  if (!!props.debug === true) debugger
  let rbac = props.sessionUserStore ? props.sessionUserStore.rbac : null
  return checkPermission(props.perform, rbac, props.data)
    ? props.yes()
    : props.no ? props.no() : <span>permission(s) "{props.perform}" manquante(s)</span>
}

export const CheckPermissionUtil = (props) => {
  if (!!props.debug === true) debugger
  let rbac = props.sessionUserStore ? props.sessionUserStore.rbac : null
  return checkPermission(props.perform, rbac, props.data)
}

CheckPermission.defaultProps = {
  yes: () => null,
  no: null
}

export default withSessionUserStore(CheckPermission)
