import { useEffect } from 'react'
import Pace from 'react-pace/vendor/pace/pace'
import 'assets/css/pace.scss'
const PaceLoader = () => {
    useEffect(() => {
        try {
            Pace.start({
                ajax: {
                    trackWebSockets: false,
                    ignoreURLs: ['.tawk.to', 'socket.io', /\/ws/]
                }

            })

        }
        catch (error) {
        }
    }, [])
    return <>
    </>
}
export default PaceLoader

