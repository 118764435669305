import {React, useContext, useEffect, useState} from "react";

import {BrowserRouter, Route, Routes, useLocation, Outlet} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import PrivateRoute from "oauth/PrivateRoute";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import {ThemeProvider} from "@mui/material/styles";
import {Alert, Snackbar, createTheme} from "@mui/material";
import theme from "./assets/jss/theme";
import {Helmet} from "react-helmet";
import './assets/css/custom-scrollbar.scss';
import './assets/css/App.css'
import KMInfraProvider from "stores/KMInfraProvider";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import SocketClient from "utils/SocketClient";
import {Worker} from "@react-pdf-viewer/core";
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import frLocale from 'dayjs/locale/fr';
import PDrawerProvider from "stores/PDrawerProvider";
import {SnackbarProvider} from "notistack";
import Autolog from "views/Autolog";
import NotLogged from "views/NotLogged";
import { KMSessionUserProvider } from "stores/KMSessionUserProvider";

function App() {
    localStorage.setItem("theme-mode", "light")
    const RouteWrapper = (props) => {
        return (
            <motion.div
                initial="initial" animate="in" exit="out"
                variants={{ initial: { opacity: 0, }, in: { opacity: 1, }, out: { opacity: 0, }, }}
                transition={{ duration: 0.2, delay: 0.005, }} >
                {props.children}
            </motion.div> );
    };

    const GlobalWrapper = (props) => {
        return (
            <motion.div initial="initial" animate="in" exit="out"
                variants={{ initial: { opacity: 0, }, in: { opacity: 1, }, out: { opacity: 0, }, }}
                transition={{ type: "spring", damping: 10, stiffness: 50, }} >
                {props.children}
            </motion.div>);
    };


    return (
        <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
                <AnimatePresence mode="wait" >
                <GlobalWrapper>
                    <KMSessionUserProvider>
                        <KMInfraProvider>
                            <LocalizationProvider
                                adapterLocale={frLocale}
                                dateAdapter={AdapterDayjs}>
                                <PDrawerProvider>
                                    <SocketClient>
                                        <></>
                                    </SocketClient>
                                    <Worker workerUrl="/pdf/pdf.worker.min.js">
                                        <RouteWrapper>
                                            <Routes>
                                                <Route exact path="/autolog/:token" element={<Autolog/>}/>
                                                <Route path="/logout" element={<NotLogged/>}/>
                                            </Routes>
                                            <PrivateRoute/>
                                        </RouteWrapper>
                                    </Worker>
                                </PDrawerProvider>
                            </LocalizationProvider>
                        </KMInfraProvider>
                    </KMSessionUserProvider>
                </GlobalWrapper>
                </AnimatePresence>
            </SnackbarProvider>
        </BrowserRouter>
    );
}

export default App;


