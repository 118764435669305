import config from '../config';
import {restIO} from "../utils/io";
import graphUserRequest from 'utils/graphUserRequest';

const superagent = require('superagent');

const graphApi = {
    // CREATE
    create: (params) => superagent.post(config.app.apiUrlPublic + '/graph', params),

    postQualifyCodeTicket: async (dataPayload) => {
        let endpoint = config.pf.url + "/pf/mail/upsert"
        let payload = dataPayload
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },

    createMeeting: async (dataPayload) => {
        let endpoint = config.pf.url + "/pf/meeting"
        let payload = dataPayload
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },
    

    // READ
    getFile: (id) => superagent.get(config.app.apiUrlPublic + `/graph/file/${id}`),
    getAllFiles: () => superagent.get(config.app.apiUrlPublic + `/graph/files`),

    getAllMails: async () => {
        let endpoint = config.api.url + '/allMails'
        let payload = {}
        let responseKeepapi = await restIO({ endpoint, method: "get", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },

    // UPDATE
    moveMail: async (dataPayload) => {
        let endpoint = config.pf.url + "/pf/mail/move"
        let payload = dataPayload
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },




    //* GRAPH USER REQUEST *//

    // READ
    userGetMe: () => graphUserRequest({ endpoint: "me" }),
    userGetPhoto: () => graphUserRequest({ endpoint: "me/photo/$value" }),
    userGetMails: () => graphUserRequest({ endpoint: "me/messages" }),
    userGetMeetings: () => graphUserRequest({ endpoint: "me/events" }),
    userGetCalendars: () => graphUserRequest({ endpoint: "me/calendars" }),
    userGetCalendarEvents: (calendarId) => graphUserRequest({ endpoint: `me/calendars/${calendarId}/events` }),
    userGetContacts: () => graphUserRequest({ endpoint: "me/contacts" }),
    userGetGroups: () => graphUserRequest({ endpoint: "me/memberOf" }),
    userGetFiles: () => graphUserRequest({ endpoint: "me/drive/root/children" }),
    userGetDrive: () => graphUserRequest({ endpoint: "me/drive" }),
    userGetDriveItems: () => graphUserRequest({ endpoint: "me/drive/items" }),
    userGetDriveRecent: () => graphUserRequest({ endpoint: "me/drive/recent" }),
    userGetDriveShared: () => graphUserRequest({ endpoint: "me/drive/sharedWithMe" }),

    userGetBatch: (batch) => graphUserRequest({ method: "POST", endpoint: "$batch", body: batch, contentType: "application/json" }),
}

export default graphApi
