import { withSessionUserStore } from 'stores/KMSessionUserProvider'
const checkRole = (allowedRoles =[], rbac = {}) => {
  const roles = rbac.roles || []
  let flagHasRight = true
  flagHasRight = !!flagHasRight === true && roles.length > 0
  flagHasRight = !!flagHasRight === true && allowedRoles.length > 0
  flagHasRight = !!flagHasRight === true && !!roles.filter(role =>
    !!allowedRoles.filter(allowedRole => allowedRole === role).length
  ).length
  return flagHasRight
}

const CheckRole = props => {
  if (!!props.debug === true) debugger
  let rbac = props.sessionUserStore ? props.sessionUserStore.rbac : null
  return checkRole(props.allowedRoles, rbac)
    ? props.yes()
    : props.no?props.no(): <span>rôle(s) "{props.allowedRoles.toString()}" manquant(s)</span>
}

export const CheckRoleUtil = (props) => {
  if (!!props.debug === true) debugger
  let rbac = props.sessionUserStore ? props.sessionUserStore.rbac : null
  return checkRole(props.allowedRoles, rbac)
}

CheckRole.defaultProps = {
  yes: () => null,
  no: null
}

export default withSessionUserStore(CheckRole)
