import { AppBar, IconButton, Toolbar, Typography, Drawer } from '@mui/material';
import { useState } from 'react';
import { ChevronLeftRounded, ChevronRightRounded, CloseRounded } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { PTooltip } from 'components/PTooltip';

const drawerWidths = ['80%', '70%', '60%', '50%', '40%', '30%'];
const drawerSmartphoneWidths = ['90%', '100%', '100%', '100%', '100%', '100%', '100%', '100%', '100%', '100%', '100%'];

const useStyles = makeStyles()((theme, { props }) => {
  const { layer = {} } = props;
  const { layerIndex, layerFullWidth = false } = layer;
  let layerWidth = !!layerFullWidth === true ? "100%" : layerIndex > drawerWidths.length ? drawerWidths[drawerWidths.length] : drawerWidths[layerIndex];
  let layerSmartphoneWidth = !!layerFullWidth === true ? "100%" :
    layerIndex > drawerSmartphoneWidths.length
      ? drawerSmartphoneWidths[drawerSmartphoneWidths.length]
      : drawerSmartphoneWidths[layerIndex];
  return {
    drawerPaper: {
      width: layerWidth,
      [theme.breakpoints.down('md')]: {
        width: layerSmartphoneWidth,
      },
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
  };
});

const TemporaryDrawer = (props) => {
  const { layer = {}, flagHasPreviousRoute, flagHasNextRoute, previousRoute, nextRoute, drawerTitle } = props;
  const { layerAnchor = 'right' } = layer;

  let { handleOnClose, children } = props;
  let { classes, cx } = useStyles({ props });
  let [openDrawer, setOpenDrawer] = useState(true);

  const onClose = () => {
    if (handleOnClose) handleOnClose();
    setOpenDrawer(false);
    if (
      window.sessionStorage.getItem('refreshParentOnClose') &&
      !!window.sessionStorage.getItem('refreshParentOnClose') === true
    ) {
      window.sessionStorage.setItem('refreshParentOnClose', false);
    }
  };
  return (
    <Drawer
      variant="temporary"

      classes={{
        paper: classes.drawerPaper,
      }}
      open={openDrawer}
      anchor={layerAnchor}
      onClose={onClose}
      PaperProps={{ elevation: 10 }}
      sx={{ overflowX: 'hidden' }}
    >

      <AppBar
        position="fixed"
        className={classes.drawerPaper}
        sx={{ overflowX: 'hidden' }}
      >
        <Toolbar variant="regular">
          <IconButton onClick={handleOnClose} edge="start" style={{ marginRight: 2 }} color="inherit" aria-label="menu">
            <CloseRounded color="secondary" />
          </IconButton>
          {flagHasPreviousRoute && (
            <PTooltip title="Précédent dans la liste">
              <IconButton
                onClick={previousRoute}
                disabled={!previousRoute}
                edge="start"
                style={{ marginRight: 2 }}
                color="inherit"
                aria-label="menu"
              >
                <ChevronLeftRounded color="secondary" />
              </IconButton>
            </PTooltip>
          )}
          <Typography variant="h6" color="inherit">
            {drawerTitle}
          </Typography>
          {flagHasNextRoute && (
            <PTooltip title="Prochain dans la liste">
              <IconButton
                onClick={nextRoute}
                disabled={!nextRoute}
                edge="start"
                style={{ marginRight: 2 }}
                color="inherit"
                aria-label="menu"
              >
                <ChevronRightRounded color="secondary" />
              </IconButton>
            </PTooltip>
          )}
        </Toolbar>
      </AppBar>
      <div style={{paddingTop:"64px", height:"100%",  overflowX: 'hidden'}}>{children}</div>
    </Drawer >
  );
};

TemporaryDrawer.propTypes = {};
export default TemporaryDrawer;
